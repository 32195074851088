// @ts-check
import {Dialog, Popover, Tab, Tabs, useTheme} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import {bool, element, func, number, object, oneOfType, shape} from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {useWindowDimensions} from "../../hooks/useWindowsDimensions";
import {selectDetails, selectDetailStatus} from "../../pages/op_management/op_management_selectors";
import {isPending} from "../../redux/utils/status";
import {OpDetailsDuration} from "../op_details/components/op_details_duration";
import {OpDetailsFactors} from "../op_details/components/op_details_factors";
import {OpDetailsOverview} from "../op_details/components/op_details_overview";
import Loading from "../shared/loading";
import OpDialogTitle from "../shared/op_dialog_title/op_dialog_title";
import {useStyles} from "./op_details_popover.styles";
import {ALIGNMENTS, calculateAnchorOrigin, calculateTransformOrigin, PANELS} from "./op_details_popover.utils";

/**
 * Popover with Op's information
 * @param {object} props
 * @param {Element} [props.anchorEl]
 * @param {{left: number, top: number}} [props.opBoxPosition] - The position of the op box
 * @param {boolean} [props.isBlockscreenVisible]
 * @param {boolean} props.isPopoverOpen
 * @param {function} props.handleClosePopover
 * @return {React.ReactElement}
 */
export const OpDetailsPopover = ({isPopoverOpen, anchorEl, handleClosePopover, opBoxPosition, isBlockscreenVisible}) => {
    const {classes, cx} = useStyles();
    const {t} = useTranslation();
    const details = useSelector(selectDetails);
    const detailStatus = useSelector(selectDetailStatus);

    const areNoDetails = isEmpty(details);

    const {height} = useWindowDimensions();
    const {LEFT, CENTER, RIGHT} = ALIGNMENTS;
    const [alignment, setAlignment] = useState(LEFT);

    // Popover
    /** @type {Theme} */
    const {
        custom: {popover}
    } = useTheme();
    const idPopover = isPopoverOpen ? "op-popover" : undefined;
    const top = opBoxPosition?.top || 0;
    const leftDistance = opBoxPosition?.left || anchorEl?.getBoundingClientRect().left;
    const bottom = anchorEl?.getBoundingClientRect().bottom;
    const spaceOnTheBottom = height - bottom;

    // Buttons Group
    const handleAlignment = (_, newAlignment) => newAlignment !== null && setAlignment(newAlignment);
    const handleClose = () => {
        handleClosePopover();
        setAlignment(LEFT);
    };
    const selectContent = (alignment, areNoDetails) => {
        if (areNoDetails) return <div data-testid="no-details">{t("OpDetails.noDetails")}</div>;
        if (alignment === LEFT) return <OpDetailsOverview />;
        if (alignment === CENTER) return <OpDetailsDuration />;
        if (alignment === RIGHT) return <OpDetailsFactors />;
        return null;
    };

    const content = isPending(detailStatus) ? (
        <Loading />
    ) : (
        <div>
            <OpDialogTitle encounterId={details?._encounterId} healthcareService={details?._healthcareService} onClose={handleClose} />
            <Tabs
                classes={{flexContainer: classes.flexContainer, indicator: classes.indicator}}
                textColor="inherit"
                value={alignment}
                onChange={handleAlignment}
            >
                {PANELS.map(({label, position}) => (
                    <Tab
                        classes={{textColorInherit: classes.tabText}}
                        data-testid={label}
                        key={label}
                        label={t(`OpDetails.${label}`)}
                        value={position}
                    />
                ))}
            </Tabs>
            <div className={classes.spacer} />
            {selectContent(alignment, areNoDetails)}
        </div>
    );
    return (
        <>
            {anchorEl ? (
                <Popover
                    anchorEl={anchorEl}
                    anchorOrigin={calculateAnchorOrigin({spaceOnTheBottom, leftDistance, popover})}
                    anchorPosition={{top, left: leftDistance - 30}}
                    anchorReference={opBoxPosition?.left && opBoxPosition?.top ? "anchorPosition" : "anchorEl"}
                    classes={{
                        root: cx({
                            [classes.isBlockscreenVisible]: isBlockscreenVisible
                        })
                    }}
                    id={idPopover}
                    open={isPopoverOpen}
                    transformOrigin={calculateTransformOrigin({spaceOnTheBottom, leftDistance, popover})}
                    onClose={handleClose}
                >
                    <div aria-label={`detailLayer`} className={classes.paper} role="dialog">
                        {content}
                    </div>
                </Popover>
            ) : (
                <Dialog
                    classes={{
                        root: cx({
                            [classes.isBlockscreenVisible]: isBlockscreenVisible
                        })
                    }}
                    open={isPopoverOpen}
                    PaperProps={{
                        classes: {root: classes.paper},
                        id: "detail-dialog"
                    }}
                    onClose={(e) => handleClosePopover()}
                >
                    {content}
                </Dialog>
            )}
        </>
    );
};
OpDetailsPopover.propTypes = {
    isPopoverOpen: bool.isRequired,
    handleClosePopover: func.isRequired,
    anchorEl: oneOfType([object, element]),
    opBoxPosition: shape({left: number, top: number}),
    isBlockscreenVisible: bool
};
