// @ts-check
import {string} from "prop-types";
import React from "react";

import useStyles from "../op_edit_layer.styles";

/**
 * render TableCell component
 * @param {Object} props
 * @param {string} props.before the value before the suggestion
 * @param {string} props.after the value suggested
 * @return {React.ReactElement}
 */
const TableCell = ({before, after}) => {
    const {classes} = useStyles();

    return (
        <div>
            {before !== after && <div className={classes.before}>{before}</div>}
            <div>{after}</div>
        </div>
    );
};
TableCell.propTypes = {
    before: string,
    after: string
};
export default TableCell;
