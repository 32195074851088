// @ts-check
import {Close} from "@mui/icons-material";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {func} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import useStyles from "../op_edit_layer.styles";

/**
 * render ConflictConfirmationDialog component
 *
 * @param {Object} props
 * @param {MouseEventHandler} props.onCancel
 * @param {MouseEventHandler} props.onApply
 * @return {React.ReactElement}
 */
const ConflictConfirmationDialog = ({onCancel, onApply}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    return (
        <div className={classes.conflictConfirmationDialogRoot} data-testid={`conflict-confirmation-dialog`}>
            <DialogTitle classes={{root: classes.conflictConfirmationTitle}}>
                <span>{t("OpEditLayer.conflictConfirmationTitle")}</span>
                <Close className={classes.icon} onClick={onCancel} />
            </DialogTitle>
            <DialogContent classes={{root: classes.conflictConfirmationContentRoot}}>
                {t("OpEditLayer.conflictConfirmationText")}
            </DialogContent>
            <DialogActions classes={{root: classes.buttonWrapper}}>
                <Button color="primary" data-testid="opEdit-cancel" variant="outlined" onClick={onCancel}>
                    {t("OpEditLayer.no")}
                </Button>

                <Button color="primary" variant="contained" onClick={onApply}>
                    {t("OpEditLayer.yes")}
                </Button>
            </DialogActions>
        </div>
    );
};

ConflictConfirmationDialog.propTypes = {
    onCancel: func.isRequired,
    onApply: func.isRequired
};

export default ConflictConfirmationDialog;
