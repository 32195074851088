import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    root: {
        flexGrow: 1,
        display: "flex",
        alignItems: "stretch",
        flexDirection: "column"
    },
    tag: {
        position: "absolute",
        top: "-0.75rem",
        height: "1.875rem",
        lineHeight: "1.875rem",
        borderRadius: "1.875rem",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightBold,
        padding: "0 1rem",
        textTransform: "uppercase"
    },
    tagRelative: {
        top: "-0.75rem",
        width: "fit-content",
        height: "1.875rem",
        lineHeight: "1.875rem",
        borderRadius: "1.875rem",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: "0.875rem",
        fontWeight: theme.typography.fontWeightBold,
        padding: "0 1rem",
        textTransform: "uppercase"
    },
    innerScrollableContent: {
        overflowY: "scroll",
        height: theme.custom.popover.innerScrollableContent.height
    },
    area: {
        height: "100%",
        width: "100%",
        margin: 0
    },
    title: {
        fontSize: "1rem",
        lineHeight: "1.25rem",
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.common.black
    },
    titleInfo: {
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing("m")
    },
    row: {
        fontSize: "0.75rem",
        marginBottom: theme.spacing("s"),
        display: "flex",
        overflow: "hidden"
    },
    rowTime: {
        fontSize: "0.75rem",
        display: "flex",
        overflow: "hidden"
    },
    label: {
        fontWeight: theme.typography.fontWeightRegular,
        width: "10rem",
        flexShrink: 0,
        color: theme.palette.grey[500]
    },
    labelPrimary: {
        fontWeight: theme.typography.fontWeightMedium,
        width: "7rem",
        flexShrink: 0,
        color: theme.palette.primary.main
    },
    value: {
        color: theme.palette.common.black
    },
    icon: {
        height: "1.25rem"
    },
    diagnosis: {
        paddingInlineStart: "1rem"
    },
    divider: {
        width: "1px",
        borderLeft: `1px dotted ${theme.palette.grey[700]}`,
        top: 0,
        position: "relative"
    },
    dividerHorizontal: {
        height: "1px",
        width: "100%",
        borderTop: `1px dotted ${theme.palette.grey[700]}`,
        top: 0,
        position: "relative"
    },
    selected: {
        borderBottom: `2px solid ${theme.palette.primary.main}`
    },
    pointer: {
        cursor: "pointer"
    },
    absolute: {
        position: "absolute"
    },
    positive: {
        backgroundColor: theme.palette.primary.main
    },
    negative: {
        backgroundColor: theme.palette.error.main
    },
    bold: {
        fontWeight: theme.typography.fontWeightBold
    },
    mt1: {
        marginTop: theme.spacing("m")
    },
    borderBottom: {
        color: `${theme.palette.grey[100]}`
    }
}));
