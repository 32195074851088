// @ts-check
import {Button, DialogContent, Radio} from "@mui/material";
import {func, string} from "prop-types";
import React, {useContext, useMemo} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {selectStandardNamesArray} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import DataTable from "../../shared/data_table/data_table";
import {formatFirstSurgeon, sortSuggestions} from "../helpers";
import useStyles from "../op_edit_layer.styles";
import {loadSuggestionsAction} from "../op_edit_layer_actions";
import {selectSuggestions, selectSuggestionsParams} from "../op_edit_layer_selectors";
import TableCell from "./table_cell";

/**
 * render SuggestionTable component
 *
 * @param {Object} props
 * @param {Function} props.onClick
 * @param {string} props.selectedSuggestion id of the selected suggestion
 * @return {React.ReactElement}
 */
const SuggestionTable = ({selectedSuggestion, onClick}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {formatFromISO} = useContext(DateContext);

    // Redux store
    const suggestions = useSelector(selectSuggestions);
    const roomInfos = useSelector(selectRoomInfos);
    const suggestionsParams = useSelector(selectSuggestionsParams); // original values
    const practitionerIds = suggestions.reduce(
        (acc, {surgeon1, surgeryApprentice1, mentor1}) => [...acc, surgeon1, surgeryApprentice1, mentor1],
        []
    );
    const {
        appointmentChange: {
            participants: {surgeon, mentor, surgeryApprentice},
            internalTimestamps,
            location
        }
    } = suggestionsParams;
    const allPractitionerIds = [...practitionerIds, surgeon?.surgeon1, mentor?.mentor1, surgeryApprentice?.surgeryApprentice1].filter(
        Boolean
    );

    const practitionerNamesList = useSelector(selectStandardNamesArray({ids: [...new Set(allPractitionerIds)], type: "practitioner"}));

    // handlers
    const handleLoadMore = () => {
        const noLimitParams = {...suggestionsParams, hasLimit: false};
        dispatch(loadSuggestionsAction(noLimitParams));
    };
    const labels = [
        {id: "isChecked", label: "", width: "5%", setTitle: false},
        {id: "plannedOn", label: t("OpEditLayer.labels.plannedOn"), width: "20%", setTitle: false},
        {id: "startTime", label: t("OpEditLayer.labels.start"), width: "15%", setTitle: false},
        {id: "endTime", label: t("OpEditLayer.labels.end"), width: "15%", setTitle: false},
        {id: "firstSurgeon", label: t("OpEditLayer.labels.firstSurgeon"), width: "30%", setTitle: true},
        {id: "location", label: t("OpEditLayer.labels.room"), width: "15%", setTitle: false}
    ];

    const beforeSurgeon1Name = practitionerNamesList.find(({id: practitionerId}) => practitionerId === surgeon?.surgeon1)?.name;
    const beforeSurgeryApprentice1Name = practitionerNamesList.find(
        ({id: practitionerId}) => practitionerId === surgeryApprentice?.surgeryApprentice1
    )?.name;
    const beforeMentor1Name = practitionerNamesList.find(({id: practitionerId}) => practitionerId === mentor?.mentor1)?.name;

    const beforeFirstSurgeon = formatFirstSurgeon(beforeSurgeon1Name, beforeSurgeryApprentice1Name, beforeMentor1Name);

    const data = useMemo(
        () =>
            suggestions.sort(sortSuggestions).map(({id, locationId, start, end, surgeon1, surgeryApprentice1, mentor1}) => {
                const surgeon1Name = practitionerNamesList.find(({id: practitionerId}) => practitionerId === surgeon1)?.name;
                const surgeryApprentice1Name = practitionerNamesList.find(
                    ({id: practitionerId}) => practitionerId === surgeryApprentice1
                )?.name;
                const mentor1Name = practitionerNamesList.find(({id: practitionerId}) => practitionerId === mentor1)?.name;

                return {
                    id,
                    display: {
                        isChecked: (
                            <Radio
                                checked={id === selectedSuggestion}
                                color="primary"
                                name={`radio-${id}}`}
                                onChange={() => onClick({id})}
                            />
                        ),
                        plannedOn: (
                            <TableCell
                                after={formatFromISO(start, DATE_FORMATS.DATE)}
                                before={formatFromISO(internalTimestamps.duraRoomLockPre.dtStart, DATE_FORMATS.DATE)}
                            />
                        ),
                        startTime: (
                            <TableCell
                                after={formatFromISO(start, DATE_FORMATS.TIME)}
                                before={formatFromISO(internalTimestamps.duraRoomLockPre.dtStart, DATE_FORMATS.TIME)}
                            />
                        ),
                        endTime: (
                            <TableCell
                                after={formatFromISO(end, DATE_FORMATS.TIME)}
                                before={formatFromISO(internalTimestamps.duraRoomLockPost.dtEnd, DATE_FORMATS.TIME)}
                            />
                        ),
                        firstSurgeon: (
                            <TableCell
                                after={formatFirstSurgeon(surgeon1Name, surgeryApprentice1Name, mentor1Name)}
                                before={beforeFirstSurgeon}
                            />
                        ),
                        location: (
                            <TableCell
                                after={roomInfos.find((room) => room.id === locationId)?.name || locationId}
                                before={roomInfos.find((room) => room.id === location)?.name || location}
                            />
                        )
                    }
                };
            }),
        [suggestions, selectedSuggestion]
    );
    return (
        <DialogContent classes={{root: classes.suggestionContentRoot}}>
            <div>{t("OpEditLayer.suggestionText")}</div>
            <div>{t("OpEditLayer.selectAnOption")}</div>
            <div className={classes.tableWrapper}>
                {
                    <DataTable
                        data={data}
                        disableSortColumns={labels.map((label) => label.id)}
                        isPaginationVisible={false}
                        labels={labels}
                        onRowClick={onClick}
                    />
                }
            </div>
            {suggestionsParams.hasLimit && (
                <Button classes={{root: classes.showMoreButton}} color="primary" onClick={handleLoadMore}>
                    {t("OpEditLayer.showMoreOptions")}
                </Button>
            )}
        </DialogContent>
    );
};

SuggestionTable.propTypes = {
    onClick: func.isRequired,
    selectedSuggestion: string
};

export default SuggestionTable;
