// @ts-check
import {createSelector} from "reselect";

/**
 * A selector for the opBacklogList slice in the store
 *
 * @param {object} state
 * @return {EditOpDataState}
 */
export const selectEditOpStore = (state) => state.editOp;

/**
 * A selector for the list of op backlog
 *
 * @param {object} state
 * @return {Array<PlanBox>}
 */
export const selectEditOpData = (state) => selectEditOpStore(state).data;

/**
 * A selector for the list of suggestions
 *
 * @param {object} state
 * @return {Array<Suggestion>}
 */
export const selectSuggestions = (state) => selectEditOpStore(state).suggestions;

/**
 * A selector for the status of suggestions
 *
 * @param {object} state
 * @return {Status}
 */
export const selectSuggestionsStatus = (state) => selectEditOpStore(state).suggestionsStatus;

/**
 * A selector for the params of suggestions to compare the difference
 *
 * @param {object} state
 * @return {FetchSuggestionsParams}
 */
export const selectSuggestionsParams = (state) => selectEditOpStore(state).suggestionsParams;

/**
 * A selector to get whether there is an error on save
 *
 * @param {object} state
 * @return {Status}
 */
export const selectSaveEditOpStatus = (state) => selectEditOpStore(state).saveEditOpStatus;

/**
 * A selector to get status for the saving surgery
 *
 * @param {object} state
 * @return {Status}
 */
export const selectSaveSurgeryStatus = (state) => selectEditOpStore(state).saveSurgeryStatus;

/**
 * A selector for the save error
 *
 * @param {object} state
 * @return {string|object}
 */
export const selectSaveError = (state) => selectEditOpStore(state).saveError;

/**
 * A selector to get whether there is an error on save
 *
 * @param {object} state
 * @return {{procedureCodeParticipants: Practitioners[], pracRoleCategoryParticipants: Practitioners[], medOpManagers: User[], surgeons: Object<string, {hcServiceIds: Array<string>, pracRoleIds: Array<string>}>, onDemandPractitioners: Array<{id: string, hcServiceId: string}>}}
 */
export const selectEditOpOptions = createSelector(selectEditOpStore, (editOpStore) => {
    const {procedureCodeParticipants, pracRoleCategoryParticipants, medOpManagers, surgeons, onDemandPractitioners} = editOpStore;
    return {procedureCodeParticipants, pracRoleCategoryParticipants, medOpManagers, surgeons, onDemandPractitioners};
});

export const selectSurgeonIds = ({hcServiceId}) =>
    createSelector([selectEditOpOptions], (editOpOptions) => {
        const {surgeons} = editOpOptions;
        return Object.entries(surgeons)
            .filter(([id, values]) => values.hcServiceIds.includes(hcServiceId))
            .map(([id]) => id);
    });
/**
 * A selector to get new position on drag and drop
 *
 * @param {object} state
 * @return {NewPosition}
 */
export const selectNewPosition = (state) => selectEditOpStore(state).newPosition;
