// @ts-check
import {object} from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

import config from "../../../../config/config.json";
import useStyles from "../op_edit_layer.styles";

/**
 * render SurgeryDetails component
 * @param {Object} props
 * @param {OpInfo} props.opInfo
 * @return {React.ReactElement}
 */
const SurgeryDetails = ({opInfo}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const {
        TEXT_PUNCTUATION: {HYPHEN}
    } = config;
    const {before, after} = opInfo.patient?.locations || {};

    const id = opInfo.id || HYPHEN;
    const surgery = opInfo.intervention || HYPHEN;
    const priority = opInfo.priority ? t(`urgency.${opInfo.priority}`) : HYPHEN;
    const positioning = opInfo.positioning || HYPHEN;
    const stationFromTo = t("OpEditLayer.fromTo", {from: before?.ward ?? HYPHEN, to: after?.ward ?? HYPHEN});

    const title = `${t("OpEditLayer.opId")}: ${id}, ${t("OpEditLayer.surgery")}: ${surgery}, ${t("OpEditLayer.priority")}: ${priority} ,${t(
        "OpEditLayer.positioning"
    )}: ${positioning}, ${t("OpEditLayer.station")}: ${stationFromTo}`;
    return (
        <div className={classes.subtitleRow} title={title}>
            <span className={classes.label}>{t("OpEditLayer.opId")}</span>
            <span className={classes.value}>{id}</span>
            <span className={classes.label}>{t("OpEditLayer.surgery")}</span>
            <span className={classes.value}>{surgery}</span>
            <span className={classes.label}>{t("OpEditLayer.priority")}</span>
            <span className={classes.value}>{priority}</span>
            <span className={classes.label}>{t("OpEditLayer.positioning")}</span>
            <span className={classes.value}>{positioning}</span>
            <span className={classes.label}>{t("OpEditLayer.station")}</span>
            <span className={classes.value}>{stationFromTo}</span>
        </div>
    );
};
SurgeryDetails.propTypes = {
    opInfo: object.isRequired
};
export default SurgeryDetails;
