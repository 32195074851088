// @ts-check
import {Close, Warning} from "@mui/icons-material";
import {Button, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {func} from "prop-types";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import {isPending, isRejected, isResolved} from "../../../redux/utils/status";
import Loading from "../../shared/loading/loading";
import useStyles from "../op_edit_layer.styles";
import {selectSuggestions, selectSuggestionsStatus} from "../op_edit_layer_selectors";
import SuggestionTable from "./suggestion_table";

/**
 * render SuggestionDialog component
 *
 * @param {Object} props
 * @param {Function} props.onApplyOption
 * @param {Function} props.onClose
 * @return {React.ReactElement}
 */
const SuggestionDialog = ({onApplyOption, onClose}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    // Redux store
    const suggestionsStatus = useSelector(selectSuggestionsStatus);
    const suggestions = useSelector(selectSuggestions);

    // State
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);

    const handleClick = ({id}) => setSelectedSuggestion(id);

    const handleApplyClick = () => onApplyOption(suggestions.find(({id}) => id === selectedSuggestion));

    return (
        <div className={classes.suggestionDialogRoot} data-testid={`suggestion-dialog`}>
            <DialogTitle classes={{root: classes.suggestionDialogTitle}}>
                <span>{t("OpEditLayer.suggestionTitle")}</span>
                <Close className={classes.icon} onClick={() => onClose()} />
            </DialogTitle>
            {isPending(suggestionsStatus) && (
                <div className={classes.loadingWrapper}>
                    <Loading />
                </div>
            )}
            {isRejected(suggestionsStatus) && (
                <DialogContent classes={{root: classes.suggestionContentRoot}}>
                    <div className={classes.errorWrapper}>
                        <div className={classes.error}>
                            <Warning className={classes.errorIcon} color={"inherit"} />
                            {t("OpEditLayer.errorText")}
                        </div>
                    </div>
                </DialogContent>
            )}
            {isResolved(suggestionsStatus) && !!suggestions.length && (
                <>
                    <SuggestionTable selectedSuggestion={selectedSuggestion} onClick={handleClick} />
                    <DialogActions classes={{root: classes.buttonWrapper}}>
                        <Button color="primary" data-testid="opEdit-cancel" variant="outlined" onClick={() => onClose()}>
                            {t("OpEditLayer.cancel")}
                        </Button>
                        <Button color="primary" disabled={!selectedSuggestion} variant="contained" onClick={handleApplyClick}>
                            {t("OpEditLayer.useThisOptionButton")}
                        </Button>
                    </DialogActions>
                </>
            )}
            {isResolved(suggestionsStatus) && !suggestions.length && (
                <DialogContent classes={{root: classes.suggestionContentRoot}}>
                    <div className={classes.errorWrapper}>
                        <div className={classes.error}>
                            <Warning className={classes.errorIcon} color={"inherit"} />
                            {t("OpEditLayer.notFoundText")}
                        </div>
                    </div>
                    <div className={classes.suggestionNotFoundText}>{t("OpEditLayer.possibleCauseText")}</div>
                    <div className={classes.whatToDo}>{t("OpEditLayer.whatYouCanDo")}</div>
                    <ul>
                        <li>{t("OpEditLayer.alternativeText1")}</li>
                        <li>{t("OpEditLayer.alternativeText2")}</li>
                    </ul>
                </DialogContent>
            )}
        </div>
    );
};

SuggestionDialog.propTypes = {
    onClose: func.isRequired,
    onApplyOption: func.isRequired
};

export default SuggestionDialog;
