// @ts-check

import {IDENTITY_SERVER, orchestratorPrefix} from "../../../config/api_config";
import axios from "../../middleware/axios.middelware";

export const URL_OP_BACKLOG = `${orchestratorPrefix}/frontend/schedule/ki/all`;
export const URL_SUGGESTIONS = `${orchestratorPrefix}/scheduler/schedule/suggestions`;
export const URL_SAVE = `${orchestratorPrefix}/scheduler/schedule/edit`;
export const URL_PROCEDURE_CODE = `${orchestratorPrefix}/procedureCode`;
export const URL_PROCROLES_CATEGORIES = `${orchestratorPrefix}/pracroles/practitioners/categories`;
export const URL_MED_PROFESSIONAL_GROUP = `${orchestratorPrefix}/pracroles/practitioners`;
export const URL_ON_DEMAND_PRACTITIONERS = `${orchestratorPrefix}/pracroles/practitioners/on-demand`;
const URL_SURGERIES = `${orchestratorPrefix}/surgeries`;

const MEDICAL_PROFESSIONAL_GROUP = "medicalProfessionalGroup";
const SURGEON = "surgeon";

/**
 * @param {string} organizationId
 * @param {string} serviceRequestId
 * @return {Promise<{data: {ok: boolean, data: Array<PlanBox>}}>}
 */
export const fetchEditOpAPI = (organizationId, serviceRequestId) => {
    return axios.get(URL_OP_BACKLOG, {params: {organizationId, serviceRequestId}});
};

/**
 * @param {string} organizationId
 * @param {FetchSuggestionsParams} params
 * @return {Promise<{data: {ok: boolean, data: Array<Suggestion>}}>}
 */
export const fetchSuggestionsAPI = (organizationId, params) => {
    return axios.get(URL_SUGGESTIONS, {params: {organizationId, ...params}});
};

/**
 * @typedef OpBacklogSaveResponse
 * @property {Boolean} ok
 * @property {{ok: boolean, msg: string}} data
 */

/**
 * @param {Object} params
 * @param {string} params.organizationId
 * @param {string} params.userEmail
 * @param {string} params.sessionId
 * @param {PatchEditOpData} data
 * @param {boolean} [validate=false]
 * @return {Promise<OpBacklogSaveResponse>}
 */
export const saveEditOpAPI = ({organizationId, userEmail, sessionId}, data, validate = false) => {
    const body = {
        organizationId,
        userEmail,
        sessionId,
        ...data
    };
    return axios.post(`${URL_SAVE}?validate=${validate}`, body);
};

/**
 * @param {String} organizationId
 * @param {Object} params
 * @param {Array<String>} params.categories The participant categories to be fetched, e.g. ["surgeon", "anesthetist", "mentor", "assistant"]
 * @param {Boolean} params.isEnabled Whether the surgery assignment is activated
 * @param {String} params.procedureCode The procedure code to be fetched, e.g. "1234"
 * @param {String} params.hcServiceId
 * @return {Promise<Array<{data: {ok: boolean, data: Object}}>>}
 */
export const fetchOptionsAPI = (organizationId, {categories, isEnabled, procedureCode, hcServiceId}) => {
    const URL_USERS = `${global.IDENTITY_SERVER_AUTHORITY}users`;
    const fetchTypeParticipants = isEnabled
        ? axios.get(`${URL_PROCEDURE_CODE}/${procedureCode}`, {
              params: {organizationId}
          })
        : new Promise((resolve) => resolve({data: {ok: true, data: []}}));
    const fetchCategoryParticipants = axios.get(`${URL_PROCROLES_CATEGORIES}`, {
        params: {hcServiceId, organizationId, participantCategories: categories}
    });
    return Promise.all([
        fetchTypeParticipants,
        fetchCategoryParticipants,
        axios.get(`${URL_USERS}/organizationId/${organizationId}`, {
            params: {roles: IDENTITY_SERVER.ROLES_MED_CLEARANCE, organizationId}
        }),
        axios.get(URL_MED_PROFESSIONAL_GROUP, {params: {organizationId, [MEDICAL_PROFESSIONAL_GROUP]: SURGEON}})
    ]);
};

/**
 * Fetches the on-demand practitioners
 *
 * @param {string} organizationId
 * @param {string} date in form of YYYY-DD-MM
 * @return {Promise<{data: {ok: boolean, data: Array<{id: string, hcServiceId: string}>}}>}
 */
export const fetchOnDemandPractitionersAPI = (organizationId, date) => {
    return axios.get(`${URL_ON_DEMAND_PRACTITIONERS}`, {params: {organizationId, date}});
};

/**
 * patch surgeries
 *
 * @param {Object} params
 * @param {string} params.organizationId
 * @param {string} params.serviceRequestId
 * @param {PatchSurgeryData} params.data the data to be saved in the surgeries
 * @return {Promise<{data: {ok: boolean}}>}
 */
export const patchSurgeries = ({organizationId, serviceRequestId, data}) => {
    return axios.patch(`${URL_SURGERIES}/${serviceRequestId}`, {organizationId, ...data});
};
