// @ts-check
import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./op_edit_layer_action_types";
/** @typedef {import("redux").Action} Action */

/** @type EditOpDataState */
const initialState = {
    data: null,
    error: null,
    loadingStatus: STATUS.IDLE,
    saveEditOpStatus: STATUS.IDLE,
    saveSurgeryStatus: STATUS.IDLE,
    newPosition: null,
    procedureCodeParticipants: [],
    pracRoleCategoryParticipants: [],
    medOpManagers: [],
    onDemandPractitioners: [],
    surgeons: {},
    saveError: null,
    suggestionsParams: null,
    suggestions: [],
    suggestionsError: null,
    suggestionsStatus: STATUS.IDLE
};

/**
 * A function to handle the loading op backlog success
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {Array<PlanBox>} action.payload
 * @return {EditOpDataState}
 */
const handleLoadEditOpSuccess = (state, {payload}) => ({
    ...state,
    data: payload,
    error: null,
    loadingStatus: STATUS.RESOLVED
});

/**
 * A function to handle the the loading op backlog request
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleLoadEditOpRequest = (state) => ({...state, loadingStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the loading op backlog failure
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {string} action.error
 * @return {EditOpDataState}
 */
const handleLoadEditOpFailure = (state, {error}) => ({...state, loadingStatus: STATUS.REJECTED, error});

/**
 * A function to handle the loading suggestions success
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {Array<Suggestion>} action.payload
 * @return {EditOpDataState}
 */
const handleloadSuggestionsSuccess = (state, {payload}) => ({
    ...state,
    suggestions: payload,
    suggestionsError: null,
    suggestionsStatus: STATUS.RESOLVED
});

/**
 * A function to handle the the loading suggestions request
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleloadSuggestionsRequest = (state, {params}) => ({
    ...state,
    suggestionsStatus: STATUS.PENDING,
    suggestionsError: null,
    suggestionsParams: params
});

/**
 * A function to handle the loadingsuggestions failure
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {string} action.error
 * @return {EditOpDataState}
 */
const handleloadSuggestionsFailure = (state, {error}) => ({...state, suggestionsStatus: STATUS.REJECTED, suggestionsError: error});

/**
 * A function to handle the modifying op backlog success
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleSaveEditOpSuccess = (state) => ({
    ...state,
    error: null,
    saveError: null,
    saveEditOpStatus: STATUS.RESOLVED
});

/**
 * A function to handle the modifying op backlog request
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleSaveEditOpRequest = (state) => ({...state, saveEditOpStatus: STATUS.PENDING, saveError: null});

/**
 * A function to handle the loading op backlog failure
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {string} action.error
 * @return {EditOpDataState}
 */
const handleSaveEditOpFailure = (state, {error}) => ({...state, saveEditOpStatus: STATUS.REJECTED, saveError: error});

const handleEditOpClear = (state) => ({...state, data: null, saveError: null, saveEditOpStatus: STATUS.IDLE});

/**
 * A function to handle the saving surgery request
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleSaveSurgeryRequest = (state) => ({...state, saveSurgeryStatus: STATUS.PENDING, error: null});

/**
 * A function to handle the saving surgery failure
 *
 * @param {EditOpDataState} state
 * @param {object} action
 * @param {string} action.error
 * @return {EditOpDataState}
 */
const handleSaveSurgeryFailure = (state, {error}) => ({...state, saveSurgeryStatus: STATUS.REJECTED, error});

/**
 * A function to handle the saving surgery success
 *
 * @param {EditOpDataState} state
 * @return {EditOpDataState}
 */
const handleSaveSurgerySuccess = (state) => ({
    ...state,
    error: null,
    saveSurgeryStatus: STATUS.RESOLVED
});

const handleSetNewPosition = (state, {newPosition}) => ({...state, newPosition});

const handleOptions = (state, {payload}) => {
    return {...state, ...payload};
};

/**
 * A function to handle the fetching on demand practitioners
 *
 * @param {EditOpDataState} state
 * @param {{payload: Array<{id: string, hcServiceId: string}>}} action
 * @return {EditOpDataState}
 */
const handleFetchOnDemandPractitioners = (state, {payload}) => ({...state, onDemandPractitioners: payload});

const handleClearSaveStatus = (state) => ({...state, saveEditOpStatus: STATUS.IDLE, saveError: null});

const handleClearStatus = (state, {statusName, errorName}) => ({...state, [statusName]: STATUS.IDLE, [errorName]: null});

const handlers = {
    [ActionTypes.FETCH_EDIT_OP_SUCCESS]: handleLoadEditOpSuccess,
    [ActionTypes.FETCH_EDIT_OP_REQUEST]: handleLoadEditOpRequest,
    [ActionTypes.FETCH_EDIT_OP_FAILURE]: handleLoadEditOpFailure,
    [ActionTypes.FETCH_SUGGESTIONS_SUCCESS]: handleloadSuggestionsSuccess,
    [ActionTypes.FETCH_SUGGESTIONS_REQUEST]: handleloadSuggestionsRequest,
    [ActionTypes.FETCH_SUGGESTIONS_FAILURE]: handleloadSuggestionsFailure,
    [ActionTypes.SAVE_EDIT_OP_SUCCESS]: handleSaveEditOpSuccess,
    [ActionTypes.SAVE_EDIT_OP_REQUEST]: handleSaveEditOpRequest,
    [ActionTypes.SAVE_EDIT_OP_FAILURE]: handleSaveEditOpFailure,
    [ActionTypes.SAVE_SURGERY_SUCCESS]: handleSaveSurgerySuccess,
    [ActionTypes.SAVE_SURGERY_REQUEST]: handleSaveSurgeryRequest,
    [ActionTypes.SAVE_SURGERY_FAILURE]: handleSaveSurgeryFailure,
    [ActionTypes.FETCH_EDIT_OP_CLEAR]: handleEditOpClear,
    [ActionTypes.SET_NEW_POSITION]: handleSetNewPosition,
    [ActionTypes.FETCH_OPTIONS]: handleOptions,
    [ActionTypes.CLEAR_SAVE_EDIT_OP_STATUS]: handleClearSaveStatus,
    [ActionTypes.CLEAR_STATUS]: handleClearStatus,
    [ActionTypes.FETCH_ON_DEMAND_PRACTITIONERS]: handleFetchOnDemandPractitioners
};

export const opEditLayerReducer = createReducer(initialState, handlers);
