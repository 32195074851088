// @ts-check
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, DialogActions, DialogContent} from "@mui/material";
import {func, object} from "prop-types";
import React, {useContext, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import {SELECTABLE_STATUS, STATUS_KEY} from "../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {selectSessionId} from "../../pages/op_management/op_management_selectors";
import {isRejected, isResolved} from "../../redux/utils/status";
import {getParticipantCategories} from "../../utils/get_participant_category";
import getPractitionerByRole from "../../utils/get_practitioner_by_role";
import isValidDate from "../../utils/is_valid_date";
import {opDisplayStatus} from "../../utils/op_status";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import {selectAllStandardNamesObject} from "../private_data/private_data_selectors";
import getPractitionerIdsFromScheduleOpByCategory from "../private_data/utils/get_practitioner_ids_from_schedule_ops_by_category";
import Message from "../shared/message/message";
import OpDialogTitle from "../shared/op_dialog_title/op_dialog_title";
import ConflictConfirmationDialog from "./components/conflict_confirmation_dialog";
import {EditLayerForm} from "./components/edit_layer_form";
import {EditLayerSchema} from "./components/edit_layer_schema";
import SuggestionDialog from "./components/suggestion_dialog";
import {
    areAllTheConflictErrorsUntouched,
    generatePayloadForSave,
    generateSuggestionsParams,
    isOptimizationItemsChanged,
    participantTypes,
    RHF_EDIT_LAYER_NAMES,
    setDefaultEndTime
} from "./helpers";
import useStyles from "./op_edit_layer.styles";
import {clearSaveEditOpStatusAction, loadSuggestionsAction, saveEditOpAction, saveSurgeryAction} from "./op_edit_layer_actions";
import {selectSaveEditOpStatus, selectSaveError, selectSaveSurgeryStatus} from "./op_edit_layer_selectors";

const SECONDS_PER_DAY = 24 * 60 * 60;
/** @type {string} */
export const CONFLICT = "Conflict";

/**
 * @typedef {import("react-hook-form").UseFormGetValues<FormEditOpData>} UseFormGetValuesEditOp
 */

/**
 * op edit layer
 * @param {Object} props
 * @param {Function} props.onClose
 * @param {PlanBox} props.originalOpData
 * @param {NewPosition} [props.newPosition]
 * @return {React.ReactElement}
 */
const OpEditLayer = ({onClose, originalOpData, newPosition}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {fromISO, fromJSDate, getLuxonToken, diffDT, setDT, getDT, plusDT, startOf, now, formatFromISO, format} = useContext(DateContext);
    // State
    const [isSuggestionOpen, setIsSuggestionOpen] = useState(false);
    const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
    const [hasActionError, setHasActionError] = useState(false);
    const [isSuggestionApplied, setIsSuggestionApplied] = useState(false);

    // Redux
    const saveSurgeryStatus = useSelector(selectSaveSurgeryStatus);

    const {
        surgeryAssignment: {isEnabled, categories},
        participantCategoriesForHealthcareService
    } = useSelector(selectFeSettings);
    const allPractitionerNames = useSelector(selectAllStandardNamesObject({type: "practitioner"}));
    const saveOpStatus = useSelector(selectSaveEditOpStatus);
    const saveError = useSelector(selectSaveError);
    const sessionId = useSelector(selectSessionId);

    const {
        _team: team,
        _medicalApproval: medicalApproval,
        _status: status,
        _statusServiceRequest: statusServiceRequest,
        _location: location,
        id,
        _patient: patient,
        _internalTimestamps: internalTimestamps,
        _healthcareService: healthcareService,
        _isCutOver: isCutOver,
        _priority: priority,
        _interventions: interventions,
        _positioning: positioning,
        _surgeonPresenting: surgeonPresenting,
        _comment: comment,
        _encounterId: encounterId,
        _error: error,
        _isLocked: isLocked,
        _requestedSurgeon: requestedSurgeon
    } = originalOpData;

    const hasNewPosition = id === newPosition?.opId;
    const startDateISO = internalTimestamps?.duraRoomLockPre?.dtStart;
    const diffSeconds = hasNewPosition && startDateISO ? diffDT(fromISO(newPosition.roomLockStart), fromISO(startDateISO), "seconds") : 0;

    const excludeTypes = isEnabled ? [RHF_EDIT_LAYER_NAMES.surgeon2, ...categories] : [RHF_EDIT_LAYER_NAMES.surgeon2];
    // form
    const dateStartISO = hasNewPosition ? newPosition.roomLockStart : startDateISO;
    const duration = internalTimestamps?.duraRoomLockPost?.refEnd || null;
    const dateEndISO = internalTimestamps?.duraRoomLockPost?.dtEnd;
    const {
        surgeons: otherSurgeonIds,
        anesthesias: anesthesiaIds,
        anesthesiaNurses: anesthesiaNurseIds,
        surgeryNurses: surgeryNurseIds
    } = getPractitionerIdsFromScheduleOpByCategory(
        team,
        getParticipantCategories({participantCategoriesForHealthcareService, hcServiceId: originalOpData._healthcareService.reference}),
        [...new Set(excludeTypes)] // these types are ignored. Since surgeon1, surgeon2. mentor1 and surgeryApprentice1 have own selector, these types are excluded
    );

    const surgeon1 = getPractitionerByRole(team, participantTypes.surgeon1);
    const surgeryApprentice1 = getPractitionerByRole(team, participantTypes.surgeryApprentice1);
    const mentor1 = getPractitionerByRole(team, participantTypes.mentor1);
    const cardiacPerfusionistIds = Object.values(team?.cardiacPerfusionist || {}).filter(Boolean);

    /**
     * isIndividual is true when
     *     1. the surgery assignment is not enabled
     *     2. the surgeon1 is set
     *     3. all the surgeon1, mentor1 and surgeryApprentice1 are empty
     */
    const isIndividual = !isEnabled || !!surgeon1 || (!surgeon1 && !surgeryApprentice1 && !mentor1);

    const medClearance = medicalApproval.find((approval) => approval.type === "next_medClearance")?.practitionerId || "";
    const medResponsible = medicalApproval.find((approval) => approval.type === "next_medResponsible")?.practitionerId || "";

    const displayStatus = opDisplayStatus(status, statusServiceRequest, isCutOver, error.code);
    const dateStartDT = fromISO(dateStartISO);

    const defaultValues = {
        [RHF_EDIT_LAYER_NAMES.status]: SELECTABLE_STATUS.includes(displayStatus) ? displayStatus : "",
        [RHF_EDIT_LAYER_NAMES.isLocked]: Boolean(isLocked),
        [RHF_EDIT_LAYER_NAMES.location]: location?.reference || "", // the new location on drop will be set in the useEffect
        [RHF_EDIT_LAYER_NAMES.dateStart]: dateStartISO ? dateStartDT.toJSDate() : null,
        [RHF_EDIT_LAYER_NAMES.startOfToday]: startOf(now(), "day").toJSDate(),
        [RHF_EDIT_LAYER_NAMES.duration]: duration || null,
        [RHF_EDIT_LAYER_NAMES.isIndividual]: isIndividual,
        // Set HH:mm:ss based on the today's date so that it will be directly editable (if it is a future date, editing mm doesn't work smoothly)
        [RHF_EDIT_LAYER_NAMES.timeStart]: dateStartISO
            ? setDT(startOf(now(), "day"), {hour: dateStartDT.hour, minute: dateStartDT.minute, second: dateStartDT.second}).toJSDate()
            : null,
        [RHF_EDIT_LAYER_NAMES.timeEnd]: setDefaultEndTime(dateEndISO, now(), diffSeconds),
        [RHF_EDIT_LAYER_NAMES.surgeon1]: surgeon1 || "",
        [RHF_EDIT_LAYER_NAMES.surgeryApprentice1]: (isEnabled && surgeryApprentice1) || "",
        [RHF_EDIT_LAYER_NAMES.mentor1]: (isEnabled && mentor1) || "",
        [RHF_EDIT_LAYER_NAMES.surgeon2]: getPractitionerByRole(team, participantTypes.surgeon2) || "",
        [RHF_EDIT_LAYER_NAMES.otherSurgeons]: otherSurgeonIds.map((item) => ({label: allPractitionerNames[item], value: item})) || [],
        [RHF_EDIT_LAYER_NAMES.anesthesias]: anesthesiaIds.map((item) => ({label: allPractitionerNames[item], value: item})) || [],
        [RHF_EDIT_LAYER_NAMES.anesthesiaNurses]: anesthesiaNurseIds.map((item) => ({label: allPractitionerNames[item], value: item})) || [],
        [RHF_EDIT_LAYER_NAMES.surgeryNurses]: surgeryNurseIds.map((item) => ({label: allPractitionerNames[item], value: item})) || [],
        [RHF_EDIT_LAYER_NAMES.cardiacPerfusionist]:
            cardiacPerfusionistIds.map((item) => ({label: allPractitionerNames[item], value: item})) || [],
        [RHF_EDIT_LAYER_NAMES.reasonForChange]: [],
        [RHF_EDIT_LAYER_NAMES.notesForReason]: "",
        [RHF_EDIT_LAYER_NAMES.surgeonPresenting]: surgeonPresenting?.reference || "",
        [RHF_EDIT_LAYER_NAMES.isNotAttending]: surgeonPresenting ? !surgeonPresenting.isAttending : false,
        [RHF_EDIT_LAYER_NAMES.medicalResponsible]: medResponsible,
        [RHF_EDIT_LAYER_NAMES.medicalClearance]: medClearance,
        [RHF_EDIT_LAYER_NAMES.comment]: comment || ""
    };

    const {
        clearErrors,
        control,
        reset,
        watch,
        formState: {errors, dirtyFields},
        setValue,
        setError,
        handleSubmit,
        resetField,
        getValues
        // @ts-ignore
    } = useForm({defaultValues, resolver: yupResolver(EditLayerSchema), mode: "onSubmit"}); // watch

    const watchDateStart = watch(RHF_EDIT_LAYER_NAMES.dateStart);
    const watchDuration = watch(RHF_EDIT_LAYER_NAMES.duration);
    const watchTimeStart = watch(RHF_EDIT_LAYER_NAMES.timeStart);
    const watchTimeEnd = watch(RHF_EDIT_LAYER_NAMES.timeEnd);
    const watchSurgeon1 = watch(RHF_EDIT_LAYER_NAMES.surgeon1);
    const watchSurgeryApprentice1 = watch(RHF_EDIT_LAYER_NAMES.surgeryApprentice1);
    const watchMentor1 = watch(RHF_EDIT_LAYER_NAMES.mentor1);
    const watchStatus = watch(RHF_EDIT_LAYER_NAMES.status);
    const watchSurgeonPresenting = watch(RHF_EDIT_LAYER_NAMES.surgeonPresenting);
    const watchIsIndividual = watch(RHF_EDIT_LAYER_NAMES.isIndividual);
    const watchIsLocked = watch(RHF_EDIT_LAYER_NAMES.isLocked);

    useEffect(() => {
        if (newPosition?.locationId && newPosition.locationId !== location?.reference) {
            setValue(RHF_EDIT_LAYER_NAMES.location, newPosition?.locationId, {shouldDirty: true});
        }
    }, [newPosition?.locationId, location?.reference]);

    // Recalculate the timeEnd if timeStart has been changed (i.e, the duration remains same)
    useEffect(() => {
        if (isValidDate(watchTimeStart) && watchDuration && typeof watchDuration === "number") {
            const newEndtimeDT = typeof watchTimeStart !== "string" && plusDT(fromJSDate(watchTimeStart), "second", watchDuration);
            setValue(RHF_EDIT_LAYER_NAMES.timeEnd, newEndtimeDT.toJSDate());
        }
        // @ts-ignore
        if (watchDuration === "") {
            setNewDurationFromStartEndTime();
        }
    }, [watchTimeStart]);

    useEffect(() => {
        if (isResolved(saveOpStatus)) {
            onClose();
        }
    }, [saveOpStatus]);

    useEffect(() => {
        if (isRejected(saveOpStatus) && saveError === CONFLICT) {
            setConflictErrors();
        }
    }, [saveOpStatus, saveError]);

    useEffect(() => {
        if (dirtyFields.isLocked) {
            resetField(RHF_EDIT_LAYER_NAMES.isLocked, {defaultValue: watchIsLocked});
            dispatch(saveSurgeryAction(id, {isLocked: watchIsLocked}));
        }
    }, [watchIsLocked, dirtyFields]);

    useEffect(() => {
        // If the saving isLocked failed, revert to the opposite value.
        if (isRejected(saveSurgeryStatus)) {
            resetField(RHF_EDIT_LAYER_NAMES.isLocked, {defaultValue: !watchIsLocked});
            setValue(RHF_EDIT_LAYER_NAMES.isLocked, !watchIsLocked);
            setHasActionError(true);
        }
    }, [saveSurgeryStatus]);

    const setConflictErrors = () => {
        setError(RHF_EDIT_LAYER_NAMES.location, {type: CONFLICT});
        setError(RHF_EDIT_LAYER_NAMES.timeStart, {type: CONFLICT});
        setError(RHF_EDIT_LAYER_NAMES.timeEnd, {type: CONFLICT});
        setError(RHF_EDIT_LAYER_NAMES.dateStart, {type: CONFLICT});
        if (watchIsIndividual) {
            setError(RHF_EDIT_LAYER_NAMES.surgeon1, {type: CONFLICT});
        } else {
            setError(RHF_EDIT_LAYER_NAMES.mentor1, {type: CONFLICT});
            setError(RHF_EDIT_LAYER_NAMES.surgeryApprentice1, {type: CONFLICT});
        }
    };

    const setNewDurationFromStartEndTime = () => {
        if (isValidDate(watchTimeEnd) && isValidDate(watchTimeStart)) {
            // Init start and end time from today because the dateStart might be invalid
            const timeStart =
                typeof watchTimeStart !== "string" &&
                setDT(now(), {
                    hour: watchTimeStart.getHours(),
                    minute: watchTimeStart.getMinutes(),
                    second: 0
                });
            const timeEnd =
                typeof watchTimeEnd !== "string" &&
                setDT(now(), {
                    hour: watchTimeEnd.getHours(),
                    minute: watchTimeEnd.getMinutes(),
                    second: 0
                });
            const newDuration = diffDT(timeEnd, timeStart, "seconds");

            if (newDuration < 0) {
                setValue(RHF_EDIT_LAYER_NAMES.duration, newDuration + SECONDS_PER_DAY);
            } else if (newDuration > SECONDS_PER_DAY) {
                setValue(RHF_EDIT_LAYER_NAMES.duration, newDuration % SECONDS_PER_DAY);
            } else {
                setValue(RHF_EDIT_LAYER_NAMES.duration, newDuration);
            }
        }
    };

    // Recalculate the duration if timeEnd has been changed (i.e, the timeStart remains same)
    useEffect(() => {
        if (dirtyFields[RHF_EDIT_LAYER_NAMES.timeEnd]) {
            setNewDurationFromStartEndTime();
            // reset dirty state of the end time
            resetField(RHF_EDIT_LAYER_NAMES.timeEnd, {keepDirty: false, defaultValue: watchTimeEnd});
        }
    }, [watchTimeEnd]);

    useEffect(() => {
        // If the watchSurgeonPresenting is cleared, clear the isNotAttending
        if (!watchSurgeonPresenting) {
            setValue(RHF_EDIT_LAYER_NAMES.isNotAttending, false);
        }
    }, [watchSurgeonPresenting]);

    // Reset surgeryApprentice1 and mentor1 if the surgery assignment is enabled and surgeon1 is set.
    useEffect(() => {
        if (isEnabled && watchSurgeon1 && watchIsIndividual) {
            setValue(RHF_EDIT_LAYER_NAMES.surgeryApprentice1, "");
            setValue(RHF_EDIT_LAYER_NAMES.mentor1, "");
        }
    }, [watchSurgeon1]);

    // Reset surgeon1 if the surgery assignment is enabled and surgeon1 is set.
    useEffect(() => {
        if (isEnabled && watchSurgeryApprentice1 && watchMentor1 && !watchIsIndividual) {
            setValue(RHF_EDIT_LAYER_NAMES.surgeon1, "");
        }
    }, [watchSurgeryApprentice1, watchMentor1]);

    // @todo #14832: these custom validation can be done in the yup schema. To be improved
    const customValidate = () => {
        let isValid = true;
        if (watchStatus === STATUS_KEY.ON_HOLD) {
            return true;
        }
        // check if all the dates are valid
        if (
            watchDateStart &&
            isValidDate(watchDateStart) &&
            watchTimeStart &&
            isValidDate(watchTimeStart) &&
            watchTimeEnd &&
            isValidDate(watchTimeEnd)
        ) {
            const startHours = typeof watchTimeStart !== "string" && watchTimeStart.getHours();
            const startMinutes = typeof watchTimeStart !== "string" && watchTimeStart.getMinutes();
            const endHours = typeof watchTimeEnd !== "string" && watchTimeEnd.getHours();
            const endMinutes = typeof watchTimeEnd !== "string" && watchTimeEnd.getMinutes();

            // Check if the HH:mm of start and end are same
            if (typeof startHours === "number" && typeof endHours === "number" && startHours === endHours && startMinutes === endMinutes) {
                setError(RHF_EDIT_LAYER_NAMES.timeEnd, {type: "invalid"});
                isValid = false;
            }

            // Check if the start is later or equal to now
            const newStart =
                typeof watchDateStart !== "string" &&
                typeof watchTimeStart !== "string" &&
                setDT(fromJSDate(watchDateStart), {
                    hour: watchTimeStart.getHours(),
                    minute: watchTimeStart.getMinutes(),
                    second: watchTimeStart.getSeconds()
                });
            if (watchStatus !== STATUS_KEY.ON_HOLD && newStart < now()) {
                setError(RHF_EDIT_LAYER_NAMES.timeStart, {type: "invalid"});
                isValid = false;
            }
        } else {
            isValid = false;
        }
        return isValid;
    };

    const onSubmit = (data) => {
        if (customValidate()) {
            if (saveError === CONFLICT && areAllTheConflictErrorsUntouched(errors, data[RHF_EDIT_LAYER_NAMES.isIndividual])) {
                setIsConfirmationDialogOpen(true);
                // keep the conflict errors
                // This might be a bug in react-hook-form: https://stackoverflow.com/questions/75967991/react-hook-form-errors-disappear-after-redux-state-change
                setConflictErrors();
            } else {
                const payload = generatePayloadForSave(
                    data,
                    {
                        id,
                        personId: patient?.id,
                        hcServiceId: healthcareService.reference,
                        internalTimestamps: internalTimestamps,
                        prio: priority,
                        error
                    },
                    {isoFormat: getLuxonToken(DATE_FORMATS.ISO_DATE), fromJSDate, setDT, getDT}
                );

                // Prepare the before and after start/end time
                const dateStartDT = fromJSDate(data[RHF_EDIT_LAYER_NAMES.dateStart]);
                const newStartDT = setDT(fromJSDate(data[RHF_EDIT_LAYER_NAMES.timeStart]), {
                    year: getDT(dateStartDT, "year"),
                    month: getDT(dateStartDT, "month"),
                    day: getDT(dateStartDT, "day")
                });

                dispatch(
                    saveEditOpAction(
                        payload,
                        watchStatus === STATUS_KEY.ON_HOLD
                            ? false
                            : isOptimizationItemsChanged(
                                  Object.keys(dirtyFields),
                                  {
                                      originalStart: formatFromISO(startDateISO, DATE_FORMATS.DATE_TIME_FORMAT),
                                      originalEnd: formatFromISO(dateEndISO, DATE_FORMATS.DATE_TIME_FORMAT)
                                  },
                                  {
                                      newStart: format(newStartDT, DATE_FORMATS.DATE_TIME_FORMAT),
                                      newEnd: format(
                                          plusDT(newStartDT, "second", Math.round(Number(watchDuration))),
                                          DATE_FORMATS.DATE_TIME_FORMAT
                                      )
                                  }
                              )
                    )
                );
            }
        }
    };

    const handleReset = () => {
        // Set reset
        reset();
    };

    /**
     * handler to open the suggestions
     * @param {boolean} hasLimit - if true, the suggestions will be limited within 2 days
     * @return {void}
     */
    const handleOpenSuggestions = (hasLimit) => {
        /** @type {FormEditOpData} */
        const formValues = getValues(); // @todo jsdoc
        const info = {
            id,
            healthcareService: healthcareService.reference,
            sessionId,
            personId: patient?.id,
            prio: priority,
            internalTimestamps
        };
        dispatch(
            loadSuggestionsAction(
                generateSuggestionsParams(
                    info,
                    formValues,
                    {fromJSDate, setDT, getDT, isoFormat: getLuxonToken(DATE_FORMATS.ISO_DATE)},
                    hasLimit
                )
            )
        );
        setIsSuggestionOpen(true);
    };
    /**
     * handler to close the suggestions
     * @return {void}
     */
    const handleCloseSuggestions = () => setIsSuggestionOpen(false);

    const handleApply = () => {
        /** @type {FormEditOpData} */
        const data = getValues();

        if (customValidate()) {
            const payload = generatePayloadForSave(
                data,
                {
                    id,
                    personId: patient?.id,
                    hcServiceId: healthcareService.reference,
                    internalTimestamps: internalTimestamps,
                    prio: priority,
                    error
                },
                {isoFormat: getLuxonToken(DATE_FORMATS.ISO_DATE), fromJSDate, setDT, getDT}
            );

            dispatch(saveEditOpAction(payload, false));
        }
    };
    const handleCancelConfirmationDialog = () => {
        setIsConfirmationDialogOpen(false);
        // keep the conflict errors
        // This might be a bug in react-hook-form: https://stackoverflow.com/questions/75967991/react-hook-form-errors-disappear-after-redux-state-change
        setConflictErrors();
    };

    /**
     * handler to apply the selected suggestion to the form
     * @param {Suggestion} suggestion
     */
    const handleApplyOption = ({locationId, start, end, surgeon1, mentor1, surgeryApprentice1}) => {
        const options = {shouldDirty: true};

        // apply to the form fields (location, start/end, surgeons)
        const startDate = fromISO(start).toJSDate();
        const endDate = fromISO(end).toJSDate();

        setValue(RHF_EDIT_LAYER_NAMES.location, locationId, options);
        setValue(RHF_EDIT_LAYER_NAMES.dateStart, startDate, options);
        setValue(RHF_EDIT_LAYER_NAMES.timeStart, startDate, options);
        setValue(RHF_EDIT_LAYER_NAMES.timeEnd, endDate, options);

        if (surgeon1) {
            setValue(RHF_EDIT_LAYER_NAMES.isIndividual, true, options);
            setValue(RHF_EDIT_LAYER_NAMES.surgeon1, surgeon1, options);
            setValue(RHF_EDIT_LAYER_NAMES.mentor1, "", options);
            setValue(RHF_EDIT_LAYER_NAMES.surgeryApprentice1, "", options);
        } else {
            setValue(RHF_EDIT_LAYER_NAMES.isIndividual, false, options);
            setValue(RHF_EDIT_LAYER_NAMES.surgeon1, "", options);
            setValue(RHF_EDIT_LAYER_NAMES.mentor1, mentor1, options);
            setValue(RHF_EDIT_LAYER_NAMES.surgeryApprentice1, surgeryApprentice1, options);
        }
        clearErrors();
        dispatch(clearSaveEditOpStatusAction());

        setIsSuggestionOpen(false); // close the suggestion
        setIsSuggestionApplied(true); // this is used to show the success message
    };

    const opInfo = {
        patient,
        intervention: interventions.main[0].display,
        priority,
        positioning,
        hcServiceId: healthcareService.reference,
        id,
        requestedSurgeon
    };

    return (
        <div className={classes.editLayerRoot} data-testid={`edit-dialog-${originalOpData.id}`}>
            {(isSuggestionOpen || isConfirmationDialogOpen) && <div className={classes.overlay} />}
            {hasActionError && <Message message={t("App.actionError")} severity="error" onClose={() => setHasActionError(false)} />}
            <OpDialogTitle encounterId={encounterId} healthcareService={healthcareService} onClose={onClose} />
            <DialogContent classes={{root: classes.contentRoot}}>
                <EditLayerForm
                    control={control}
                    errors={errors}
                    isSuggestionApplied={isSuggestionApplied}
                    opInfo={opInfo}
                    setValue={setValue}
                    onOpenSuggestions={handleOpenSuggestions}
                />
            </DialogContent>
            <DialogActions classes={{root: classes.buttonWrapper}}>
                <div>
                    <Button color="primary" data-testid="opEdit-cancel" variant="outlined" onClick={(e) => onClose()}>
                        {t("OpEditLayer.cancel")}
                    </Button>

                    <Button className={classes.reset} color="primary" data-testid="opEdit-reset" onClick={handleReset}>
                        {t("OpEditLayer.reset")}
                    </Button>
                </div>
                <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
                    {t("OpEditLayer.save")}
                </Button>
            </DialogActions>
            {isSuggestionOpen && <SuggestionDialog onApplyOption={handleApplyOption} onClose={handleCloseSuggestions} />}
            {isConfirmationDialogOpen && <ConflictConfirmationDialog onApply={handleApply} onCancel={handleCancelConfirmationDialog} />}
        </div>
    );
};
OpEditLayer.propTypes = {
    onClose: func.isRequired,
    originalOpData: object.isRequired,
    newPosition: object // only set if the layer is opened by drag and drop
};

export default OpEditLayer;
