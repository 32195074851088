// @ts-check
import {DoneAll, Info, Warning} from "@mui/icons-material";
import {FormControlLabel, Radio, RadioGroup, Tooltip} from "@mui/material";
import uniq from "lodash/uniq";
import {bool, func, object} from "prop-types";
import React, {useContext, useEffect} from "react";
import {useController} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {STATUS_KEY} from "../../../../config/op_status";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {isPending} from "../../../redux/utils/status";
import {getParticipantCategories} from "../../../utils/get_participant_category";
import {selectFeSettings, selectThemeLocationInfos} from "../../fe_settings/fe_settings_selectors";
import {selectAllStandardNamesObject} from "../../private_data/private_data_selectors";
import {selectRoomInfos} from "../../rooms/rooms_selectors";
import {
    ControlledAutocompleteMultiple,
    ControlledDatePicker,
    ControlledSelectorSingle,
    ControlledTextField,
    ControlledTimePicker
} from "../../shared/controlled_form";
import {ControlledCheckbox} from "../../shared/controlled_form/controlled_checkbox/controlled_checkbox";
import {checkAssignment, formatOptions, RHF_EDIT_LAYER_NAMES} from "../helpers";
import {CONFLICT} from "../op_edit_layer";
import useStyles from "../op_edit_layer.styles";
import {fetchOnDemandPractitionersAction} from "../op_edit_layer_actions";
import {selectEditOpOptions, selectSaveError, selectSaveSurgeryStatus, selectSurgeonIds} from "../op_edit_layer_selectors";
import PatientDetails from "./patient_details";
import SurgeryDetails from "./surgery_details";

/** @typedef {import('react-hook-form').UseFormGetValues<any>} UseFormGetValues */

/**
 * A form containing all the filters for the surgeries table
 *
 * @param {object} props The props
 * @param {object} props.control The RHF control object
 * @param {object} [props.errors]
 * @param {OpInfo} props.opInfo
 * @param {Function} props.setValue
 * @param {Function} props.onOpenSuggestions
 * @param {boolean} props.isSuggestionApplied Whether the suggestion has been applied
 * @return {React.ReactElement}
 */
export const EditLayerForm = ({control, errors, opInfo, setValue, onOpenSuggestions, isSuggestionApplied}) => {
    const {t} = useTranslation();
    const {classes, cx} = useStyles();
    const dispatch = useDispatch();
    const {getLuxonToken, startOf, now, minusDT, format, fromJSDate} = useContext(DateContext);

    // redux
    const saveSurgeryStatus = useSelector(selectSaveSurgeryStatus);

    const {
        field: {value: isIndividual}
    } = useController({
        name: RHF_EDIT_LAYER_NAMES.isIndividual,
        control
    });
    const {
        field: {value: status}
    } = useController({
        name: RHF_EDIT_LAYER_NAMES.status,
        control
    });
    const {
        field: {value: surgeonPresenting}
    } = useController({
        name: RHF_EDIT_LAYER_NAMES.surgeonPresenting,
        control
    });

    const {
        field: {value: dateStart}
    } = useController({name: RHF_EDIT_LAYER_NAMES.dateStart, control});

    const {MAX_LENGTH_COMMENT} = config;

    // Use Effects
    /**
     * Re-fetches on demand practitioners when the starting date of the op is changed by the user
     */
    useEffect(() => {
        const dateFormatted = format(startOf(fromJSDate(dateStart), "day"), DATE_FORMATS.SYSTEM_DATE);

        if (dateFormatted !== "Invalid DateTime") {
            // @ts-ignore @todo fix this
            dispatch(fetchOnDemandPractitionersAction(dateFormatted));
        }
    }, [dateStart]);

    // Redux store
    const allPractitionerNames = useSelector(selectAllStandardNamesObject({type: "practitioner"}));
    const saveError = useSelector(selectSaveError);

    const roomInfos = useSelector(selectRoomInfos);
    const locationInfos = useSelector(selectThemeLocationInfos); // includes sort and short labels
    const {
        surgeryAssignment: {isEnabled, categories: surgeonTypes}, // here the categories are actually participantTypes like surgeon1, mentor1
        reasonsForChange,
        participantCategoriesForHealthcareService
    } = useSelector(selectFeSettings);

    const participantCategories = getParticipantCategories({
        participantCategoriesForHealthcareService,
        hcServiceId: opInfo.hcServiceId
    });

    const {procedureCodeParticipants, pracRoleCategoryParticipants, medOpManagers, onDemandPractitioners} =
        useSelector(selectEditOpOptions);

    const surgeonIds = useSelector(selectSurgeonIds({hcServiceId: opInfo.hcServiceId}));

    // Check if the customer has enabled surgery assignment and the surgery has any assignments
    const hasNoAssignment = isEnabled && !checkAssignment(procedureCodeParticipants, surgeonTypes);

    /** @type EditLayerOptions */
    const options = formatOptions({
        roomInfos,
        locationInfos,
        medOpManagers,
        reasonsForChange,
        surgeonIds,
        allPractitionerNames,
        isEnabled,
        hasNoAssignment,
        participantCategories,
        procedureCodeParticipants,
        onDemandPractitioners,
        pracRoleCategoryParticipants,
        opInfo
    });

    // Handlers
    const toggleFirstSurgeonSelection = () => {
        setValue(RHF_EDIT_LAYER_NAMES.isIndividual, !isIndividual);
    };

    if (opInfo.requestedSurgeon) {
        ["surgeon1", "surgeon2", "otherSurgeons", "surgeonsPresenting", "medicalResponsible"].forEach((type) => {
            if (type && !options[type].find((option) => option.value === opInfo.requestedSurgeon)) {
                options[type].push({
                    label: allPractitionerNames[opInfo.requestedSurgeon] || t("App.unknown"),
                    value: opInfo.requestedSurgeon
                });
                options[type].sort((a, b) => a.label?.localeCompare(b.label));
            }
        });
    }

    const participantsMenuList = [
        [
            {type: "surgeon2", isMultiple: false},
            {type: "otherSurgeons", isMultiple: true}
        ],
        [
            {type: "surgeryNurses", isMultiple: true},
            {type: "anesthesias", isMultiple: true}
        ],
        [
            {type: "anesthesiaNurses", isMultiple: true},
            {type: "cardiacPerfusionist", isMultiple: true}
        ]
    ];

    let messages = Object.values(errors).map((error) => error.message);
    messages = uniq(messages);

    const errorMessage =
        saveError === CONFLICT ? (
            <>
                <span>{t("OpEditLayer.conflictError")}</span>
                <span
                    className={classes.optionLink}
                    role="button"
                    tabIndex={0}
                    onClick={() => onOpenSuggestions(true)}
                    onKeyDown={(e) => e.key === "Enter" && onOpenSuggestions(true)}
                >
                    {t("OpEditLayer.calculateOptions")}
                </span>
            </>
        ) : (
            <span>{t("App.defaultFormError")}</span>
        );
    return (
        <>
            <div className={classes.subtitle}>
                <PatientDetails id={opInfo?.patient?.id} observations={opInfo?.patient?.observations} />
                <SurgeryDetails opInfo={opInfo} />
            </div>
            <form className={classes.form}>
                <div className={classes.leftPane}>
                    <div className={classes.notificationWrapper}>
                        {isSuggestionApplied && (
                            <div className={classes.success}>
                                <DoneAll className={classes.notificationIcon} color={"inherit"} />
                                {t("OpEditLayer.suggestionSuccessfullyApplied")}
                            </div>
                        )}
                        {!!messages?.length && (
                            <div className={classes.error}>
                                <Warning className={classes.notificationIcon} color={"inherit"} />
                                {errorMessage}
                            </div>
                        )}
                    </div>

                    <div className={classes.formRow}>
                        <ControlledSelectorSingle
                            classesStyle={classes.select}
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.status]}
                            hasOwnErrorMessage={false}
                            items={options.statusOptions}
                            name={RHF_EDIT_LAYER_NAMES.status}
                            placeholder={t("App.pleaseSelect")}
                            required
                            setValue={setValue}
                            showResetIcon
                            title={t("OpEditLayer.status")}
                        />
                    </div>
                    <div className={classes.formRow}>
                        <ControlledSelectorSingle
                            classesStyle={classes.selectHalf}
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.location]}
                            hasOwnErrorMessage={false}
                            items={options.roomOptions}
                            name={RHF_EDIT_LAYER_NAMES.location}
                            placeholder={t("App.pleaseSelect")}
                            required
                            setValue={setValue}
                            showResetIcon
                            title={t("OpEditLayer.room")}
                        />
                        <ControlledDatePicker
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.dateStart]}
                            hasOwnErrorMessage={false}
                            inputFormat={getLuxonToken(DATE_FORMATS.DATE)}
                            label={t("OpEditLayer.date")}
                            minDate={status === STATUS_KEY.ON_HOLD ? startOf(minusDT(now(), "month", 3), "day") : startOf(now(), "day")}
                            name={RHF_EDIT_LAYER_NAMES.dateStart}
                            required
                            startOrEnd={"start"}
                            styles={{inputDate: cx(classes.selectHalf, classes.ml20)}}
                        />
                        <ControlledTimePicker
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.timeStart]}
                            hasOwnErrorMessage={false}
                            inputFormat={getLuxonToken(DATE_FORMATS.TIME)}
                            label={t("OpEditLayer.timeStart")}
                            name={RHF_EDIT_LAYER_NAMES.timeStart}
                            required
                            styles={{inputDate: cx(classes.selectHalf, classes.ml40)}}
                        />
                        <ControlledTimePicker
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.timeEnd]}
                            hasOwnErrorMessage={false}
                            inputFormat={getLuxonToken(DATE_FORMATS.TIME)}
                            label={t("OpEditLayer.timeEnd")}
                            name={RHF_EDIT_LAYER_NAMES.timeEnd}
                            required
                            styles={{inputDate: cx(classes.selectHalf, classes.ml20)}}
                        />
                    </div>
                    <div className={classes.row}>
                        {isEnabled ? (
                            <div>
                                <span className={classes.text}>{t("OpEditLayer.planSurgeon1")}</span>
                                <div className={classes.row}>
                                    <RadioGroup defaultValue={"individual"} name="first-surgeon" row>
                                        {["individual", "combo"].map((type) => (
                                            <FormControlLabel
                                                classes={{
                                                    root: cx(classes.radioRoot, {
                                                        [classes.radioRootNoLeftPadding]: type === "individual"
                                                    }),
                                                    label: classes.radioLabel
                                                }}
                                                control={
                                                    <Radio
                                                        checked={type === "individual" ? isIndividual : !isIndividual}
                                                        color="primary"
                                                        name={`radio-${type}`}
                                                        value={type}
                                                        onChange={toggleFirstSurgeonSelection}
                                                    />
                                                }
                                                key={type}
                                                label={t(`OpEditLayer.${type}`)}
                                                labelPlacement="end"
                                                value={type}
                                            />
                                        ))}
                                    </RadioGroup>
                                    {hasNoAssignment && (
                                        <div className={classes.errorNoAssignment}>
                                            <Warning className={classes.errorIconNoAssignment} color={"inherit"} />
                                            <span>{t("OpEditLayer.noSurgeryAssingment")}</span>
                                        </div>
                                    )}
                                </div>

                                <div
                                    className={cx(classes.formRow, {
                                        [classes.hide]: !isIndividual
                                    })}
                                >
                                    <ControlledSelectorSingle
                                        classesStyle={classes.select}
                                        control={control}
                                        errors={errors[RHF_EDIT_LAYER_NAMES.surgeon1]}
                                        hasOwnErrorMessage={false}
                                        items={options.surgeon1}
                                        name={RHF_EDIT_LAYER_NAMES.surgeon1}
                                        placeholder={t("App.pleaseSelect")}
                                        required
                                        setValue={setValue}
                                        showResetIcon
                                        title={t("OpEditLayer.surgeon1AsO")}
                                    />
                                </div>
                                <div
                                    className={cx(classes.formRow, {
                                        [classes.hide]: isIndividual
                                    })}
                                >
                                    <ControlledSelectorSingle
                                        classesStyle={classes.select}
                                        control={control}
                                        errors={errors[RHF_EDIT_LAYER_NAMES.surgeryApprentice1]}
                                        hasOwnErrorMessage={false}
                                        items={options.surgeryApprentice1}
                                        name={RHF_EDIT_LAYER_NAMES.surgeryApprentice1}
                                        placeholder={t("App.pleaseSelect")}
                                        required
                                        setValue={setValue}
                                        showResetIcon
                                        title={t("OpEditLayer.surgeryApprentice1")}
                                    />
                                    <ControlledSelectorSingle
                                        classesStyle={cx(classes.select, classes.ml40)}
                                        control={control}
                                        errors={errors[RHF_EDIT_LAYER_NAMES.mentor1]}
                                        hasOwnErrorMessage={false}
                                        items={options.mentor1}
                                        name={RHF_EDIT_LAYER_NAMES.mentor1}
                                        placeholder={t("App.pleaseSelect")}
                                        required
                                        setValue={setValue}
                                        showResetIcon
                                        title={t("OpEditLayer.mentor1")}
                                    />
                                </div>
                            </div>
                        ) : (
                            <ControlledSelectorSingle
                                classesStyle={classes.select}
                                control={control}
                                errors={errors[RHF_EDIT_LAYER_NAMES.surgeon1]}
                                hasOwnErrorMessage={false}
                                items={options.surgeon1}
                                name={RHF_EDIT_LAYER_NAMES.surgeon1}
                                placeholder={t("App.pleaseSelect")}
                                required
                                setValue={setValue}
                                showResetIcon
                                title={t("OpEditLayer.surgeon1")}
                            />
                        )}
                    </div>
                    {participantsMenuList.map((rowMenuList) => (
                        <div className={classes.formRow} key={rowMenuList.map((menu) => menu.type).join("-")}>
                            {rowMenuList.map((menu, i) =>
                                menu.isMultiple ? (
                                    <ControlledAutocompleteMultiple
                                        classesStyle={cx(classes.select, {
                                            [classes.ml40]: i !== 0
                                        })}
                                        control={control}
                                        hasTooltip
                                        items={options[menu.type]}
                                        key={menu.type}
                                        name={RHF_EDIT_LAYER_NAMES[menu.type]}
                                        placeholder={t("App.pleaseSelect")}
                                        title={t(`OpEditLayer.${menu.type}`)}
                                    />
                                ) : (
                                    <ControlledSelectorSingle
                                        classesStyle={cx(classes.select, {
                                            [classes.ml40]: i !== 0
                                        })}
                                        control={control}
                                        items={options[menu.type]}
                                        key={menu.type}
                                        name={RHF_EDIT_LAYER_NAMES[menu.type]}
                                        placeholder={t("App.pleaseSelect")}
                                        setValue={setValue}
                                        showResetIcon
                                        title={t(`OpEditLayer.${menu.type}`)}
                                    />
                                )
                            )}
                        </div>
                    ))}
                    <div className={classes.formRow}>
                        <ControlledAutocompleteMultiple
                            classesStyle={classes.select}
                            control={control}
                            hasTooltip
                            items={options.reasonForChange}
                            name={RHF_EDIT_LAYER_NAMES.reasonForChange}
                            placeholder={t("App.pleaseSelect")}
                            title={t(`OpEditLayer.reasonForChange`)}
                        />
                        <ControlledTextField
                            classesStyle={cx(classes.select, classes.heightAuto, classes.ml40)}
                            control={control}
                            inputProps={{"data-testid": "notesForReason"}}
                            label={t("OpEditLayer.notesForReason")}
                            name={RHF_EDIT_LAYER_NAMES.notesForReason}
                            placeholder={t("App.pleaseWrite")}
                        />
                    </div>
                    <div className={classes.formRow}>
                        <ControlledSelectorSingle
                            classesStyle={classes.select}
                            control={control}
                            errors={errors[RHF_EDIT_LAYER_NAMES.surgeonPresenting]}
                            hasOwnErrorMessage={false}
                            items={options.surgeonsPresenting}
                            name={RHF_EDIT_LAYER_NAMES.surgeonPresenting}
                            placeholder={t("App.pleaseSelect")}
                            setValue={setValue}
                            showResetIcon
                            title={t("OpEditLayer.surgeonPresenting")}
                        />
                        <ControlledCheckbox
                            classesStyle={{root: classes.checkboxRoot, label: classes.checkBoxLabel}}
                            control={control}
                            cx={cx}
                            disabled={!surgeonPresenting}
                            label={t("OpEditLayer.isNotAttending")}
                            labelPlacement={"end"}
                            name={RHF_EDIT_LAYER_NAMES.isNotAttending}
                        />
                    </div>
                </div>
                <div className={cx(classes.rightPane, classes.selectMedClearance)}>
                    <ControlledSelectorSingle
                        classesStyle={cx(classes.select)}
                        control={control}
                        items={options.medicalResponsible}
                        name={RHF_EDIT_LAYER_NAMES.medicalResponsible}
                        placeholder={t("App.pleaseSelect")}
                        setValue={setValue}
                        showResetIcon
                        title={t("OpEditLayer.medicalResponsible")}
                    />
                    <ControlledSelectorSingle
                        classesStyle={classes.select}
                        control={control}
                        items={options.medicalOpManagerOptions}
                        name={RHF_EDIT_LAYER_NAMES.medicalClearance}
                        placeholder={t("App.pleaseSelect")}
                        setValue={setValue}
                        showCheckIcon
                        showResetIcon
                        title={t("OpEditLayer.medicalClearance")}
                    />
                    <ControlledTextField
                        classesStyle={cx(classes.select, classes.comment)}
                        control={control}
                        inputProps={{"data-testid": "comment", "maxLength": MAX_LENGTH_COMMENT}}
                        label={t("OpEditLayer.comment")}
                        multiline
                        name={RHF_EDIT_LAYER_NAMES.comment}
                        placeholder={t("App.pleaseWrite")}
                    />
                    <div className={classes.lockWrapper}>
                        <ControlledCheckbox
                            classesStyle={{label: classes.checkBoxLabel}}
                            control={control}
                            cx={cx}
                            disabled={isPending(saveSurgeryStatus)}
                            label={t("OpEditLayer.isLocked")}
                            labelPlacement={"end"}
                            name={RHF_EDIT_LAYER_NAMES.isLocked}
                        />
                        <Tooltip title={t("OpEditLayer.infoForIsLocked")}>
                            <Info className={classes.iconIsLocked} color="primary" />
                        </Tooltip>
                    </div>
                </div>
            </form>
        </>
    );
};
EditLayerForm.propTypes = {
    control: object.isRequired,
    errors: object,
    opInfo: object.isRequired,
    setValue: func.isRequired,
    onOpenSuggestions: func.isRequired,
    isSuggestionApplied: bool.isRequired
};
