import {makeStyles} from "tss-react/mui";

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const useStyles = makeStyles()(({palette: {grey}, typography: {fontWeightBold, fontWeightRegular}, custom: {popover}}) => ({
    dialog: {
        borderRadius: "0px",
        position: "relative"
    },
    paper: {
        borderRadius: 0,
        padding: "40px",
        width: popover.width,
        height: popover.height,
        overflow: "hidden"
    },
    isBlockscreenVisible: {
        zIndex: "1000 !important"
    },
    flexContainer: {
        borderBottom: `1px solid ${grey[200]}`
    },
    tabText: {
        fontSize: "0.875rem",
        fontWeight: fontWeightBold,
        textTransform: "capitalize",
        minWidth: "120px"
    },
    indicator: {
        height: "4px",
        width: "120px !important"
    },
    spacer: {
        marginBottom: "22px"
    }
}));
