// @ts-check
import {makeStyles} from "tss-react/mui";

export default makeStyles()((/** @type AllCustomTheme */ theme) => ({
    editLayerRoot: {
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "& h2": {
            padding: "40px 40px 16px",
            fontSize: "23px",
            textTransform: "unset",
            fontWeight: theme.typography.fontWeightRegular
        }
    },
    reset: {
        textTransform: "capitalize",
        fontSize: "14px",
        marginLeft: "40px"
    },
    contentRoot: {
        // padding: 0,
        color: theme.palette.grey[800]
    },
    form: {
        display: "flex"
    },
    formRow: {
        display: "flex"
    },
    subtitle: {
        paddingBottom: "10px",
        borderBottom: `1px dotted ${theme.palette.grey[500]}`,
        fontSize: "1rem"
    },
    subtitleRow: {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "1020px" // 1100px - 80px
    },
    row: {
        display: "flex",
        whiteSpace: "nowrap"
    },
    label: {
        "fontWeight": theme.typography.fontWeightMedium,
        "marginRight": theme.spacing("s"),
        "&:after": {
            display: "inline",
            content: '":"'
        }
    },
    value: {
        marginRight: theme.spacing("m")
    },
    leftPane: {
        width: "680px",
        borderRight: `1px dotted ${theme.palette.grey[500]}`
    },
    rightPane: {
        width: "340px",
        paddingLeft: "40px"
    },
    medClearance: {
        fontSize: "14px",
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: "20px",
        height: "45px",
        display: "flex",
        alignItems: "flex-end"
    },
    text: {
        fontSize: "14px",
        fontWeight: theme.typography.fontWeightMedium
    },
    radioRoot: {
        "height": "16px",
        "margin": "16px 0 20px",
        "& svg": {
            height: "18px",
            width: "18px"
        }
    },
    radioRootNoLeftPadding: {
        "& .MuiRadio-root": {
            paddingLeft: 0
        }
    },
    radioLabel: {
        fontSize: "14px"
    },
    select: {
        "width": "300px",
        "flexShrink": 0,
        "marginBottom": theme.spacing("m"),
        "& .MuiChip-root": {
            maxWidth: "220px !important"
        },
        "& .MuiChip-label": {
            paddingLeft: 0
        },
        "& .Mui-error": {
            color: theme.palette.error.main
        },
        "& input": {
            "&::placeholder": {
                opacity: 1,
                color: theme.palette.grey[500]
            }
        }
    },
    heightAuto: {
        "& .MuiInput-input": {
            height: "auto" // in order to align the height of ControlledTextField with other ControlledComponents
        }
    },
    comment: {
        "& .MuiInput-input": {
            "height": "auto", // in order to align the height of ControlledTextField with other ControlledComponents
            "maxHeight": "380px",
            "overflow": "auto !important",
            "&::placeholder": {
                fontSize: "0.875rem",
                lineHeight: "1.375rem",
                height: "1.375rem",
                color: theme.palette.grey[500],
                opacity: 1
            }
        }
    },
    selectHalf: {
        "width": "140px",
        "paddingTop": "4px",
        "marginBottom": theme.spacing("m"),
        "& input": {
            padding: "6px 0 7px",
            height: "22px"
        },
        "& .Mui-error": {
            color: theme.palette.error.main
        }
    },
    selectMedClearance: {
        marginTop: "50px", // same height with status pull down
        position: "relative"
    },
    autocomplete: {
        width: "300px",
        flexShrink: 0
    },
    switchLabel: {
        fontSize: "14px",
        color: theme.palette.grey[800]
    },
    ml20: {
        marginLeft: "20px"
    },
    ml40: {
        marginLeft: "40px"
    },
    buttonWrapper: {
        display: "flex",
        justifyContent: "space-between !important"
    },
    notificationWrapper: {
        paddingTop: "1rem",
        height: "50px"
    },
    success: {
        color: theme.palette.success.main,
        display: "flex",
        alignItems: "flex-end"
    },
    notificationIcon: {
        width: "24px",
        height: "24px",
        marginRight: "1rem"
    },
    error: {
        color: theme.palette.error.main,
        display: "flex",
        alignItems: "flex-end"
    },
    errorNoAssignment: {
        color: theme.palette.error.main,
        display: "flex",
        alignItems: "center",
        marginLeft: theme.spacing("m"),
        fontSize: "12px",
        height: "28px",
        alignSelf: "center"
    },
    errorIconNoAssignment: {
        width: "22px",
        height: "22px",
        marginRight: "0.5rem",
        alignSelf: "flex-start"
    },
    checkBoxLabel: {
        fontSize: "0.875rem",
        color: theme.palette.grey[500]
    },
    checkboxRoot: {
        marginLeft: "32px"
    },
    optionLink: {
        color: theme.palette.primary.main,
        textDecoration: "underline",
        cursor: "pointer",
        marginLeft: theme.spacing("s")
    },
    suggestionContentRoot: {
        minHeight: "300px",
        color: theme.palette.grey[800]
    },
    conflictConfirmationContentRoot: {
        minHeight: "70px",
        color: theme.palette.grey[800]
    },
    overlay: {
        position: "absolute",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 10
    },
    conflictConfirmationDialogRoot: {
        position: "absolute",
        boxShadow: theme.shadows[3],
        width: "547px",
        backgroundColor: theme.custom.background.layer,
        padding: 0,
        zIndex: 20,
        height: "260px",
        left: "calc(50% - 273px)"
    },
    suggestionDialogRoot: {
        position: "absolute",
        boxShadow: theme.shadows[3],
        width: "1100px",
        backgroundColor: theme.custom.background.layer,
        padding: 0,
        zIndex: 20,
        minHeight: "300px"
    },
    loadingWrapper: {
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0
    },
    suggestionDialogTitle: {
        display: "flex",
        justifyContent: "space-between"
    },
    conflictConfirmationTitle: {
        display: "flex",
        justifyContent: "space-between",
        textTransform: "none"
    },
    iconIsLocked: {
        cursor: "pointer",
        fontSize: "1.5rem",
        alignSelf: "center"
    },
    suggestionNotFoundText: {
        margin: `${theme.spacing("m")} 0`
    },
    whatToDo: {
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: theme.spacing("m")
    },
    before: {
        textDecoration: "line-through"
    },
    tableWrapper: {
        "maxHeight": "400px",
        "maxWidth": "800px",
        "overflow": "auto",
        "& .MuiTableCell-stickyHeader": {
            backgroundColor: theme.palette.background.paper
        }
    },
    hovered: {
        background: "rbga(217, 217, 217, 0.13)"
    },
    icon: {
        fontSize: "26px",
        alignSelf: "center",
        cursor: "pointer"
    },
    showMoreButton: {
        textTransform: "none",
        marginTop: theme.spacing("m")
    },
    hide: {
        display: "none"
    },
    lockWrapper: {
        "display": "flex",
        "position": "absolute",
        "bottom": 0,
        "height": "67px",
        "& label": {
            marginRight: "4px"
        }
    }
}));
