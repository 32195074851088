/**
 *@type {{label: ("overview"|"duration"|"snzDetails"), position: ("left"|"center"|"right")}[]}
 */
export const PANELS = [
    {label: "overview", position: "left"},
    {label: "duration", position: "center"}
    // {label: "snzDetails", position: "right"} // #12556 hide this tab
];
/**
 *@type {{LEFT: "left", CENTER: "center", RIGHT: "right"}}
 */
export const ALIGNMENTS = Object.freeze({
    LEFT: "left",
    CENTER: "center",
    RIGHT: "right"
});

/**
 * @typedef {Object} PointLocation
 * @property {("bottom"|"top")} vertical
 * @property {("right"|"left")} horizontal
 */

/**
 * @typedef {Object} PositionAndSize
 * @property {number} spaceOnTheBottom
 * @property {number} leftDistance
 * @property {{height: string, width: string}} popover - The popover height and width
 */

/**
 * Set the point on the anchor where the popover's anchorEl will attach to.
 * @param {PositionAndSize} Object - Element's position data and popover size
 * @return {PointLocation} - Anchor coordinates
 */
export const calculateAnchorOrigin = ({spaceOnTheBottom, leftDistance, popover: {width, height}}) => {
    let horizontal = "right";
    let vertical = "bottom";

    const opPopoverHeight = parseInt(height);
    const opPopoverWidth = parseInt(width);

    if (spaceOnTheBottom < opPopoverHeight) {
        vertical = "top";
    }
    if (leftDistance > opPopoverWidth) {
        horizontal = "left";
    }

    return {
        vertical,
        horizontal
    };
};

/**
 * Set the point on the anchor where the popover's anchorEl will attach to.
 * @param {PositionAndSize} Object - Element's position data
 * @return {PointLocation} - Anchor coordinates
 */
export const calculateTransformOrigin = ({spaceOnTheBottom, leftDistance, popover: {width, height}}) => {
    let horizontal = "right";
    let vertical = "top";

    const opPopoverHeight = parseInt(height);
    const opPopoverWidth = parseInt(width);

    if (spaceOnTheBottom < opPopoverHeight) {
        vertical = "bottom";
    }
    if (leftDistance < opPopoverWidth) {
        horizontal = "left";
    }
    return {
        vertical,
        horizontal
    };
};
