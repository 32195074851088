// @ts-check
import * as yup from "yup";

import config from "../../../../config/config.json";
import {STATUS_KEY} from "../../../../config/op_status";
import {RHF_EDIT_LAYER_NAMES} from "../helpers";

export const EditLayerSchema = yup.object().shape({
    [RHF_EDIT_LAYER_NAMES.status]: yup.string().required(),
    [RHF_EDIT_LAYER_NAMES.location]: yup.string().when(RHF_EDIT_LAYER_NAMES.status, {
        is: (status) => status !== STATUS_KEY.ON_HOLD,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.startOfToday]: yup.date(),
    [RHF_EDIT_LAYER_NAMES.dateStart]: yup.date().when([RHF_EDIT_LAYER_NAMES.status], {
        is: (status) => status !== STATUS_KEY.ON_HOLD,
        then: () => yup.date().min(yup.ref(RHF_EDIT_LAYER_NAMES.startOfToday)).required(),
        otherwise: () => yup.date().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.timeStart]: yup.date().when(RHF_EDIT_LAYER_NAMES.status, {
        is: (status) => status !== STATUS_KEY.ON_HOLD,
        then: () => yup.date().required(),
        otherwise: () => yup.date().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.timeEnd]: yup.date().when(RHF_EDIT_LAYER_NAMES.status, {
        is: (status) => status !== STATUS_KEY.ON_HOLD,
        then: () => yup.date().required(),
        otherwise: () => yup.date().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.surgeon1]: yup.string().when([RHF_EDIT_LAYER_NAMES.isIndividual, RHF_EDIT_LAYER_NAMES.status], {
        is: (isIndividual, status) => isIndividual && status !== STATUS_KEY.ON_HOLD,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.surgeryApprentice1]: yup.string().when([RHF_EDIT_LAYER_NAMES.isIndividual, RHF_EDIT_LAYER_NAMES.status], {
        is: (isIndividual, status) => !isIndividual && status !== STATUS_KEY.ON_HOLD,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.mentor1]: yup.string().when([RHF_EDIT_LAYER_NAMES.isIndividual, RHF_EDIT_LAYER_NAMES.status], {
        is: (isIndividual, status) => !isIndividual && status !== STATUS_KEY.ON_HOLD,
        then: () => yup.string().required(),
        otherwise: () => yup.string().notRequired()
    }),
    [RHF_EDIT_LAYER_NAMES.comment]: yup.string().test("len", "can be max 100", (val) => {
        if (val === undefined) {
            return true;
        }
        return val.length <= config.MAX_LENGTH_COMMENT;
    }),
    [RHF_EDIT_LAYER_NAMES.duration]: yup.number().notRequired(),
    [RHF_EDIT_LAYER_NAMES.surgeonPresenting]: yup.string().notRequired(),
    [RHF_EDIT_LAYER_NAMES.surgeon2]: yup.string().notRequired(),
    [RHF_EDIT_LAYER_NAMES.otherSurgeons]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.anesthesias]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.anesthesiaNurses]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.surgeryNurses]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.cardiacPerfusionist]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.reasonForChange]: yup.array().notRequired(),
    [RHF_EDIT_LAYER_NAMES.notesForReason]: yup.string().notRequired(),
    [RHF_EDIT_LAYER_NAMES.isNotAttending]: yup.boolean().notRequired(),
    [RHF_EDIT_LAYER_NAMES.medicalResponsible]: yup.string().notRequired(),
    [RHF_EDIT_LAYER_NAMES.medicalClearance]: yup.string().notRequired(),
    [RHF_EDIT_LAYER_NAMES.isLocked]: yup.boolean().notRequired(),
    [RHF_EDIT_LAYER_NAMES.isIndividual]: yup.boolean().notRequired()
});
