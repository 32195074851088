// @ts-check
const ActionTypes = {
    FETCH_EDIT_OP_REQUEST: "editOp/FETCH_EDIT_OP_REQUEST",
    FETCH_EDIT_OP_SUCCESS: "editOp/FETCH_EDIT_OP_SUCCESS",
    FETCH_EDIT_OP_FAILURE: "editOp/FETCH_EDIT_OP_FAILURE",
    FETCH_EDIT_OP_CLEAR: "editOp/FETCH_EDIT_OP_CLEAR",
    FETCH_SUGGESTIONS_REQUEST: "editOp/FETCH_SUGGESTIONS_REQUEST",
    FETCH_SUGGESTIONS_SUCCESS: "editOp/FETCH_SUGGESTIONS_SUCCESS",
    FETCH_SUGGESTIONS_FAILURE: "editOp/FETCH_SUGGESTIONS_FAILURE",
    SAVE_EDIT_OP_REQUEST: "editOp/SAVE_EDIT_OP_REQUEST",
    SAVE_EDIT_OP_SUCCESS: "editOp/SAVE_EDIT_OP_SUCCESS",
    SAVE_EDIT_OP_FAILURE: "editOp/SAVE_EDIT_OP_FAILURE",
    SET_NEW_POSITION: "editOp/SET_NEW_POSITION",
    FETCH_OPTIONS: "editOp/FETCH_OPTIONS",
    CLEAR_SAVE_EDIT_OP_STATUS: "editOp/CLEAR_SAVE_EDIT_OP_STATUS",
    CLEAR_SAVE_SURGERY_STATUS: "editOp/CLEAR_SAVE_SURGERY_STATUS",
    CLEAR_STATUS: "editOp/CLEAR_STATUS",
    SAVE_SURGERY_REQUEST: "editOp/SAVE_SURGERY_REQUEST",
    SAVE_SURGERY_SUCCESS: "editOp/SAVE_SURGERY_SUCCESS",
    SAVE_SURGERY_FAILURE: "editOp/SAVE_SURGERY_FAILURE",
    FETCH_ON_DEMAND_PRACTITIONERS: "editOp/FETCH_ON_DEMAND_PRACTITIONERS"
};

export default ActionTypes;
