// @ts-check
import {object, string} from "prop-types";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import config from "../../../../config/config.json";
import {DATE_FORMATS, DateContext} from "../../../contexts/dates";
import {formatObservation} from "../../../utils/patient_helpers";
import {selectPatientInfo} from "../../private_data/private_data_selectors";
import buildPatientInfo from "../../private_data/utils/build_patient_info";
import useStyles from "../op_edit_layer.styles";

/**
 * render PatientDetails component
 * @param {Object} props
 * @param {string} props.id
 * @param {ObservationsPatient} props.observations
 * @return {React.ReactElement}
 */
const PatientDetails = ({id, observations}) => {
    const {classes} = useStyles();
    const {t} = useTranslation();
    const {getLuxonToken} = useContext(DateContext);

    const {
        TEXT_PUNCTUATION: {VERTICAL_SLASH}
    } = config;

    const patientInfo = useSelector((state) => selectPatientInfo(state, {id}));

    const details = [
        ...Object.values(buildPatientInfo(patientInfo, id, getLuxonToken(DATE_FORMATS.BIRTH_DATE_FORMAT))),
        formatObservation(observations?.weight),
        formatObservation(observations?.height),
        formatObservation(observations?.infectiousStatus)
    ];
    return (
        <div className={classes.row}>
            <span className={classes.label}>{t("OpEditLayer.patient")}</span>
            <span>{details.join(` ${VERTICAL_SLASH} `)}</span>
        </div>
    );
};
PatientDetails.propTypes = {
    id: string.isRequired,
    observations: object.isRequired
};
export default PatientDetails;
