// @ts-check
import flatten from "lodash/flatten";
import uniq from "lodash/uniq";

import {luxonToken} from "../../contexts/dates";
import {loadOpManagementScheduleAction} from "../../pages/op_management/op_management_actions";
import {selectIsBacklogView, selectSelectedDate, selectSessionId} from "../../pages/op_management/op_management_selectors";
import {authUserFailureAction, setRefreshTrigger} from "../../redux/actions/index";
import {selectCurrentOrganizationId, selectCurrentUserEmail} from "../../redux/app_selectors";
import {getParticipantCategories} from "../../utils/get_participant_category";
import logger from "../../utils/logger_pino";
import {format} from "../../utils/luxon_helpers";
import {selectFeSettings} from "../fe_settings/fe_settings_selectors";
import {loadNamesAction} from "../private_data/private_data_actions";
import {assignUniqueId} from "./helpers";
import ActionTypes from "./op_edit_layer_action_types";
import {
    fetchEditOpAPI,
    fetchOnDemandPractitionersAPI,
    fetchOptionsAPI,
    fetchSuggestionsAPI,
    patchSurgeries,
    saveEditOpAPI
} from "./op_edit_layer_api";
import {selectSuggestionsParams} from "./op_edit_layer_selectors";

const loadEditOpRequestAction = () => ({
    type: ActionTypes.FETCH_EDIT_OP_REQUEST
});

const loadEditOpSuccessAction = (payload) => ({
    type: ActionTypes.FETCH_EDIT_OP_SUCCESS,
    payload
});

const loadEditOpFailureAction = (error) => ({
    type: ActionTypes.FETCH_EDIT_OP_FAILURE,
    error
});

/**
 * load op for edit
 * @param {String} id
 * @return {Action}
 */
function loadEditOpAction(id) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        dispatch(loadEditOpRequestAction());
        const organizationId = selectCurrentOrganizationId(getState());
        const email = selectCurrentUserEmail(getState());

        fetchEditOpAPI(organizationId, id)
            .then(({data}) => {
                if (data.data?.length) {
                    dispatch(loadEditOpSuccessAction(data.data));
                } else {
                    // If the clicked appointment was not found
                    const msg = "OpEditLayer: no appointment found";
                    logger.error(msg, {
                        organizationId,
                        email
                    });
                    dispatch(loadEditOpFailureAction(msg));
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch edit op error"}));
                } else {
                    dispatch(loadEditOpFailureAction(error));
                }
            });
    };
}

const clearEditOpAction = () => ({
    type: ActionTypes.FETCH_EDIT_OP_CLEAR
});

const loadSuggestionsRequestAction = (params) => ({
    type: ActionTypes.FETCH_SUGGESTIONS_REQUEST,
    params
});

const loadSuggestionsSuccessAction = (payload) => ({
    type: ActionTypes.FETCH_SUGGESTIONS_SUCCESS,
    payload
});

const loadSuggestionsFailureAction = (error) => ({
    type: ActionTypes.FETCH_SUGGESTIONS_FAILURE,
    error
});

/**
 * load op for edit
 * @param {FetchSuggestionsParams} params
 * @return {AnyAction}
 */
function loadSuggestionsAction(params) {
    // @ts-ignore @todo: fix this
    return function (dispatch, getState) {
        dispatch(loadSuggestionsRequestAction(params));
        const organizationId = selectCurrentOrganizationId(getState());

        fetchSuggestionsAPI(organizationId, params)
            .then(({data}) => {
                const currentSuggestionParams = selectSuggestionsParams(getState());
                // only save the suggestions if the opId of the given params equals to the one which is opening in the edit layer
                if (currentSuggestionParams?.appointmentChange?.id === params.appointmentChange.id) {
                    dispatch(loadSuggestionsSuccessAction(assignUniqueId(data.data || [])));

                    // load names
                    if (data.data?.length) {
                        const practitionerIds = data.data.reduce(
                            (acc, {surgeon1, surgeryApprentice1, mentor1}) => [...acc, surgeon1, surgeryApprentice1, mentor1],
                            []
                        );
                        dispatch(loadNamesAction(organizationId, "practitioner", uniq(practitionerIds.filter(Boolean)), false));
                    }
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(authUserFailureAction({error: true, message: "fetch edit op error"}));
                } else if (error.response?.status === 404) {
                    // handle as success
                    dispatch(loadSuggestionsSuccessAction([]));
                } else {
                    dispatch(loadSuggestionsFailureAction(error));
                }
            });
    };
}

/**
 * set new position for drag & drop
 * @param {NewPosition} [newPosition]
 * @return {AnyAction}
 */
const setNewPositionAction = (newPosition) => ({
    type: ActionTypes.SET_NEW_POSITION,
    newPosition
});

/**
 * An action creator to start the request to modify the surgery assignment data
 *
 * @return {AnyAction}
 */
const saveEditOpRequestAction = () => ({
    type: ActionTypes.SAVE_EDIT_OP_REQUEST
});

/**
 * An action creator for the success
 *
 * @return {AnyAction}
 */
const saveEditOpSuccessAction = () => ({
    type: ActionTypes.SAVE_EDIT_OP_SUCCESS
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {AnyAction}
 */
const saveEditOpFailureAction = (error) => ({
    type: ActionTypes.SAVE_EDIT_OP_FAILURE,
    error
});

/**
 * @callback ActionFunction
 * @param {any} dispatch
 * œ@param {any} getState
 * @return {Void}
 */

/**
 * An action creator for modifying op in the op backlog
 *
 * @param {PatchEditOpData} data
 * @param {boolean} [validate=false]
 * @return {AnyAction}
 */
// @ts-ignore
const saveEditOpAction = (data, validate) => (dispatch, getState) => {
    dispatch(saveEditOpRequestAction());
    const state = getState();
    const organizationId = selectCurrentOrganizationId(state);
    const sessionId = selectSessionId(state);
    const userEmail = selectCurrentUserEmail(state);
    const isOpBacklog = selectIsBacklogView(state);
    const selectedDate = selectSelectedDate(state);

    saveEditOpAPI({organizationId, userEmail, sessionId}, data, validate)
        .then(({data}) => {
            if (data.ok) {
                // refetch
                if (isOpBacklog) {
                    dispatch(setRefreshTrigger());
                } else {
                    dispatch(loadOpManagementScheduleAction(organizationId, format(selectedDate, luxonToken.SYSTEM_DATE.de)));
                }
                dispatch(saveEditOpSuccessAction());
            } else {
                dispatch(saveEditOpFailureAction("An error has occured in save edit op action"));
            }
        })
        .catch((error) => {
            dispatch(saveEditOpFailureAction(error?.response?.data?.msg || "saveEditOp failed"));
        });
};

const setOption = (payload) => ({type: ActionTypes.FETCH_OPTIONS, payload});

/**
 * An action creator for fetching options for the edit layer
 *
 * @param {String} procedureCode
 * @param {String} hcServiceId
 * @return {ActionFunction}
 */
const fetchOptionsAction = (procedureCode, hcServiceId) => (dispatch, getState) => {
    const state = getState();
    const organizationId = selectCurrentOrganizationId(state);
    const email = selectCurrentUserEmail(state);
    const {
        participantCategoriesForHealthcareService,
        surgeryAssignment: {isEnabled}
    } = selectFeSettings(state);
    const categories = flatten(
        Object.values(
            getParticipantCategories({
                participantCategoriesForHealthcareService,
                hcServiceId
            })
        )
    );
    fetchOptionsAPI(organizationId, {categories, isEnabled, procedureCode, hcServiceId})
        .then(
            ([
                {data: procedureCodeParticipants},
                {data: pracRoleCategoryParticipants},
                {data: medOpManagers},
                {data: medProfessionalGroupSurgeons}
            ]) => {
                const payload = {
                    procedureCodeParticipants: procedureCodeParticipants?.data || [],
                    pracRoleCategoryParticipants: pracRoleCategoryParticipants?.data || [],
                    medOpManagers: medOpManagers?.data || [],
                    surgeons: medProfessionalGroupSurgeons?.data || {}
                };
                dispatch(setOption(payload));

                // load names
                const practitionerIds = [];
                payload.procedureCodeParticipants.forEach((participant) => practitionerIds.push(...participant.practitionerIds));
                payload.pracRoleCategoryParticipants.forEach((participant) => practitionerIds.push(...participant.practitionerIds));
                payload.medOpManagers.forEach((user) => user.practitionerId && practitionerIds.push(user.practitionerId));
                practitionerIds.push(...Object.keys(payload.surgeons));
                dispatch(loadNamesAction(organizationId, "practitioner", uniq(practitionerIds), false));
            }
        )
        .catch((error) => {
            logger.warn("OpEdit: fetch options failed", {
                organizationId,
                email
            });
        });
};

/**
 * An action creator for fetching on demand practitioners for the edit layer
 *
 * @param {string} date in form of YYYY-DD-MM
 * @return {ActionFunction}
 */
const fetchOnDemandPractitionersAction = (date) => (dispatch, getState) => {
    const organizationId = selectCurrentOrganizationId(getState());
    fetchOnDemandPractitionersAPI(organizationId, date)
        .then(({data: {data: onDemandPractitioners}}) => {
            dispatch(setOnDemandPractitioners(onDemandPractitioners));
            dispatch(
                loadNamesAction(
                    organizationId,
                    "practitioner",
                    onDemandPractitioners.map(({id}) => id),
                    false
                )
            );
        })
        .catch((error) => {
            logger.warn("OpEdit: fetch on demand practitioners failed", {
                organizationId
            });
        });
};

/**
 * An action creator for setting on demand practitioners
 *
 * @param {Array<{id: string, hcServiceId: string}>} payload
 * @return {{type: string, payload: Array<{id: string, hcServiceId: string}>}}
 */
const setOnDemandPractitioners = (payload) => ({type: ActionTypes.FETCH_ON_DEMAND_PRACTITIONERS, payload});

const clearSaveEditOpStatusAction = () => ({type: ActionTypes.CLEAR_SAVE_EDIT_OP_STATUS});

/**
 * An action creator to start the request to modify the surgery assignment data
 *
 * @return {AnyAction}
 */
const saveSurgeryRequestAction = () => ({
    type: ActionTypes.SAVE_SURGERY_REQUEST
});

/**
 * An action creator for the success
 *
 * @return {AnyAction}
 */
const saveSurgerySuccessAction = () => ({
    type: ActionTypes.SAVE_SURGERY_SUCCESS
});

/**
 * An action creator for the error
 *
 * @param {string} error
 * @return {AnyAction}
 */
const saveSurgeryFailureAction = (error) => ({
    type: ActionTypes.SAVE_SURGERY_FAILURE,
    error
});

/**
 * An action creator for modifying op in the op backlog
 *
 * @param {string} serviceRequestId
 * @param {PatchSurgeryData} data
 * @return {AnyAction}
 */
// @ts-ignore
const saveSurgeryAction = (serviceRequestId, data) => (dispatch, getState) => {
    dispatch(saveSurgeryRequestAction());
    const state = getState();
    const organizationId = selectCurrentOrganizationId(state);

    patchSurgeries({organizationId, serviceRequestId, data})
        .then(() => {
            dispatch(saveSurgerySuccessAction());
        })
        .catch((error) => {
            dispatch(saveSurgeryFailureAction(error));
        });
};

const clearStatusAction = (statusName, errorName) => ({type: ActionTypes.CLEAR_STATUS, statusName, errorName});

export {
    loadEditOpAction,
    clearEditOpAction,
    loadSuggestionsAction,
    setNewPositionAction,
    saveEditOpAction,
    fetchOptionsAction,
    clearSaveEditOpStatusAction,
    saveSurgeryAction,
    clearStatusAction,
    fetchOnDemandPractitionersAction
};
